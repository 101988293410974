import React from 'react';
const Sort1 = (props) => (
  <svg
    width={6}
    height={9}
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.03924 2.16527L2.9938 0.119822C2.83406 -0.0399408 2.57502 -0.0399408 2.41526 0.119822L0.369813 2.16527C0.252841 2.28227 0.21785 2.45823 0.281123 2.6111C0.34445 2.76396 0.493631 2.86364 0.659094 2.86364H2.29545V8.59092C2.29545 8.81685 2.47859 9.00001 2.70454 9.00001C2.93044 9.00001 3.11363 8.81685 3.11363 8.59092V2.86361H4.74999C4.91543 2.86361 5.06461 2.76393 5.12791 2.61107C5.19126 2.45821 5.15627 2.28227 5.03924 2.16527Z"
      fill="white"
    />
  </svg>
);
export default Sort1;
