import React from 'react';
const GreenLabelTag = (props) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 16V0H14L6 8L14 16H0Z" fill="#95EBB7" />
  </svg>
);
export default GreenLabelTag;
