export const receipt_data = [
  {
    id: 'bc1e0563-767c-40aa-877f-e0f833788a85',
    reference: 'Ref001',
    from: 'Vendor A',
    to: 'Doc001',
    contact: 'visen industries limited',
    status: 'Pending',
    schedule_date: 'today',
    source_document: 'P000011',
  },
  {
    id: '47a151d6-a952-485e-8f1f-943597b2b2b3',
    reference: 'Ref002',
    from: 'Vendor B',
    to: 'Doc002',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000012',
  },
  {
    id: '1553ace3-609c-4a26-a8fc-a3c899d0fb91',
    reference: 'Ref003',
    from: 'Vendor C',
    to: 'Doc003',
    contact: 'visen industries limited',
    status: 'Pending',
    schedule_date: 'today',
    source_document: 'P000013',
  },
  {
    id: '253f5ac5-f355-4573-bddb-eac001591765',
    reference: 'Ref004',
    from: 'Vendor D',
    to: 'Doc004',
    contact: 'visen industries limited',
    status: 'In Progress',
    schedule_date: 'today',
    source_document: 'P000014',
  },
  {
    id: 'e18d2909-308e-4049-9ce3-b62359879b26',
    reference: 'Ref005',
    from: 'Vendor E',
    to: 'Doc005',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000015',
  },
  {
    id: '39d66729-fdf6-48a7-89ef-ed792fcf233b',
    reference: 'Ref006',
    from: 'Vendor F',
    to: 'Doc006',
    contact: 'visen industries limited',
    status: 'Pending',
    schedule_date: 'today',
    source_document: 'P000016',
  },
  {
    id: '05b85a9f-32a6-42e7-b6eb-dfb6cabf5f1d',
    reference: 'Ref007',
    from: 'Vendor G',
    to: 'Doc007',
    contact: 'visen industries limited',
    status: 'In Progress',
    schedule_date: 'today',
    source_document: 'P000017',
  },
  {
    id: '87e33de1-50d4-4cbc-8793-1d73aad83b05',
    reference: 'Ref008',
    from: 'Vendor H',
    to: 'Doc008',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000018',
  },
  {
    id: 'e99e29e6-23dc-4853-be69-58bca3339449',
    reference: 'Ref009',
    from: 'Vendor I',
    to: 'Doc009',
    contact: 'visen industries limited',
    status: 'Pending',
    schedule_date: 'today',
    source_document: 'P000019',
  },
  {
    id: 'cfb7c0e8-d51a-4a38-b7f9-0bc5c9dff620',
    reference: 'Ref010',
    from: 'Vendor J',
    to: 'Doc010',
    contact: 'visen industries limited',
    status: 'In Progress',
    schedule_date: 'today',
    source_document: 'P000020',
  },
  {
    id: 'dd333706-3c9c-4f6e-a856-f881c113be09',
    reference: 'Ref011',
    from: 'Vendor K',
    to: 'Doc011',
    contact: 'visen industries limited',
    status: 'Pending',
    schedule_date: 'today',
    source_document: 'P000021',
  },
  {
    id: 'b64be21f-ceb0-4c1f-9ceb-29acc590e633',
    reference: 'Ref012',
    from: 'Vendor L',
    to: 'Doc012',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000022',
  },
  {
    id: 'c82c82bb-137a-4fef-9467-f43690ea9d40',
    reference: 'Ref013',
    from: 'Vendor L',
    to: 'Doc013',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000023',
  },
  {
    id: '87328c24-6cc4-4b23-93d3-d42a71c49a30',
    reference: 'Ref014',
    from: 'Vendor L',
    to: 'Doc014',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000024',
  },
  {
    id: '181f1176-e634-4c79-9163-3210c0dca0d1',
    reference: 'Ref015',
    from: 'Vendor L',
    to: 'Doc015',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000025',
  },
  {
    id: '08abea30-e583-4ff7-8bea-2c2d1e5e2422',
    reference: 'Ref016',
    from: 'Vendor L',
    to: 'Doc016',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000026',
  },
  {
    id: 'b876f5fa-8986-41e5-9c53-609bb3873e75',
    reference: 'Ref017',
    from: 'Vendor L',
    to: 'Doc017',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000027',
  },
  {
    id: '93e5bef4-9c71-461b-85f7-974acef991e2',
    reference: 'Ref018',
    from: 'Vendor L',
    to: 'Doc018',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000028',
  },
  {
    id: '79b39a17-1ca0-4779-b0fd-536bd7f8cbfd',
    reference: 'Ref019',
    from: 'Vendor L',
    to: 'Doc019',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000029',
  },
  {
    id: '725ae2f4-2de9-4de2-b89c-997957588af4',
    reference: 'Ref020',
    from: 'Vendor L',
    to: 'Doc020',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000030',
  },
  {
    id: '3f7e2959-37ca-4e25-81ab-9ad626114e70',
    reference: 'Ref021',
    from: 'Vendor L',
    to: 'Doc021',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000031',
  },
  {
    id: '46b01ec0-8598-432a-9032-13b63c27513f',
    reference: 'Ref022',
    from: 'Vendor L',
    to: 'Doc022',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000032',
  },
  {
    id: 'fcd8950f-676c-4cb5-806a-d9cd03d91dc3',
    reference: 'Ref023',
    from: 'Vendor L',
    to: 'Doc023',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000033',
  },
  {
    id: '45c58cf6-435a-4997-80dc-4dce4be172aa',
    reference: 'Ref024',
    from: 'Vendor L',
    to: 'Doc024',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000034',
  },
  {
    id: 'c7d32fbf-7aff-4281-9cca-d8c4a8932045',
    reference: 'Ref025',
    from: 'Vendor L',
    to: 'Doc025',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000035',
  },
  {
    id: '8cf30777-1289-46ca-83fe-519978f05531',
    reference: 'Ref026',
    from: 'Vendor L',
    to: 'Doc026',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000036',
  },
  {
    id: 'baa343cb-7e63-4236-bc6d-e0adb65537df',
    reference: 'Ref027',
    from: 'Vendor L',
    to: 'Doc027',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000037',
  },
  {
    id: 'e679cd46-93e1-4ddc-8635-bfd0b2dea80b',
    reference: 'Ref028',
    from: 'Vendor L',
    to: 'Doc028',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000038',
  },
  {
    id: 'fd1a7d37-c32e-49f2-a0e7-f7aeb87bf489',
    reference: 'Ref029',
    from: 'Vendor L',
    to: 'Doc029',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000039',
  },
  {
    id: '3b4e6a30-aa87-4e28-b7f3-c340c88f0519',
    reference: 'Ref030',
    from: 'Vendor L',
    to: 'Doc030',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000040',
  },
  {
    id: '3150b196-9ee4-4967-8e07-8db2a2465447',
    reference: 'Ref031',
    from: 'Vendor L',
    to: 'Doc031',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000041',
  },
  {
    id: '097b7f31-4592-433e-b607-cfea85379c07',
    reference: 'Ref032',
    from: 'Vendor L',
    to: 'Doc032',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000042',
  },
  {
    id: '3f1a4fd9-96e4-4c4c-ad39-46ea2b717347',
    reference: 'Ref033',
    from: 'Vendor L',
    to: 'Doc033',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000043',
  },
  {
    id: 'bf3be562-d396-4a19-957f-d57943df4044',
    reference: 'Ref034',
    from: 'Vendor L',
    to: 'Doc034',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000044',
  },
  {
    id: 'cac73c4f-439e-4396-a4e1-30e89b6b58a8',
    reference: 'Ref035',
    from: 'Vendor L',
    to: 'Doc035',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000045',
  },
  {
    id: '88f6d275-70fa-491c-9c1b-dff0eaf15823',
    reference: 'Ref036',
    from: 'Vendor L',
    to: 'Doc036',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000046',
  },
  {
    id: '3d711ae0-3eee-416d-9554-31cbdec6c312',
    reference: 'Ref037',
    from: 'Vendor L',
    to: 'Doc037',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000047',
  },
  {
    id: '7d59146d-cafb-4892-a2ad-684bc9af21ff',
    reference: 'Ref038',
    from: 'Vendor L',
    to: 'Doc038',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000048',
  },
  {
    id: '013bac2c-cf78-4f68-96b6-3cc7d7340bc8',
    reference: 'Ref039',
    from: 'Vendor L',
    to: 'Doc039',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000049',
  },
  {
    id: '5d9dbed0-eed9-41e5-8472-c4e65f8d72e8',
    reference: 'Ref040',
    from: 'Vendor L',
    to: 'Doc040',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000050',
  },
  {
    id: '073915a8-a68e-40f0-8e80-2ee567ca5562',
    reference: 'Ref041',
    from: 'Vendor L',
    to: 'Doc041',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000051',
  },
  {
    id: '1c1f3c20-9953-4bb4-9dc3-0a5ec165089c',
    reference: 'Ref042',
    from: 'Vendor L',
    to: 'Doc042',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000052',
  },
  {
    id: '4a9c00b9-abc1-440b-b6ae-67a25ee7be8d',
    reference: 'Ref043',
    from: 'Vendor L',
    to: 'Doc043',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000053',
  },
  {
    id: '02dffb18-39b7-4196-9f41-bbe5d10bb48f',
    reference: 'Ref044',
    from: 'Vendor L',
    to: 'Doc044',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000054',
  },
  {
    id: 'b2844ec7-65dc-4238-9f7e-ca5dd5218a5d',
    reference: 'Ref045',
    from: 'Vendor L',
    to: 'Doc045',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000055',
  },
  {
    id: 'e2b6e075-82ad-49c5-9174-02c573f95b0e',
    reference: 'Ref046',
    from: 'Vendor L',
    to: 'Doc046',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000056',
  },
  {
    id: '56dbeb51-adf9-4a45-bbc0-baa3576e09be',
    reference: 'Ref047',
    from: 'Vendor L',
    to: 'Doc047',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000057',
  },
  {
    id: 'b4f95167-61ac-41d8-99bd-ede18136ff3a',
    reference: 'Ref048',
    from: 'Vendor L',
    to: 'Doc048',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000058',
  },
  {
    id: '36d26171-508b-4911-abae-910801c05796',
    reference: 'Ref049',
    from: 'Vendor L',
    to: 'Doc049',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000059',
  },
  {
    id: 'a7a417c5-f585-4c82-b446-10065f4af1f5',
    reference: 'Ref050',
    from: 'Vendor L',
    to: 'Doc050',
    contact: 'visen industries limited',
    status: 'Completed',
    schedule_date: 'today',
    source_document: 'P000060',
  },
];

export const clothingDispatchInnerData = [
  { product: 'Yarn', lotno: '74598', demand_quantity: 100, producing_quantity: 100, unit: 'KG' },
  { product: 'Cotton', lotno: '12459', demand_quantity: 200, producing_quantity: 200, unit: 'KG' },
  { product: 'Wool', lotno: '98234', demand_quantity: 150, producing_quantity: 150, unit: 'KG' },
  { product: 'Silk', lotno: '58439', demand_quantity: 80, producing_quantity: 80, unit: 'KG' },
  { product: 'Poly', lotno: '39475', demand_quantity: 120, producing_quantity: 120, unit: 'KG' },
  { product: 'Linen', lotno: '67492', demand_quantity: 60, producing_quantity: 60, unit: 'KG' },
  { product: 'Nylon', lotno: '83745', demand_quantity: 90, producing_quantity: 90, unit: 'KG' },
  { product: 'Acrylic', lotno: '12975', demand_quantity: 110, producing_quantity: 110, unit: 'KG' },
  { product: 'Rayon', lotno: '67384', demand_quantity: 130, producing_quantity: 130, unit: 'KG' },
];

export const receiptInnerData = [
  { product: 'Yarn', lotno: '74598', demand_quantity: 100, received_quantity: 0, unit: 'KG' },
  { product: 'Cotton', lotno: '12459', demand_quantity: 200, received_quantity: 200, unit: 'KG' },
  { product: 'Wool', lotno: '98234', demand_quantity: 150, received_quantity: 0, unit: 'KG' },
  { product: 'Silk', lotno: '58439', demand_quantity: 80, received_quantity: 80, unit: 'KG' },
  {
    product: 'Polyester',
    lotno: '39475',
    demand_quantity: 120,
    received_quantity: 120,
    unit: 'KG',
  },
  { product: 'Linen', lotno: '67492', demand_quantity: 60, received_quantity: 60, unit: 'KG' },
  { product: 'Nylon', lotno: '83745', demand_quantity: 90, received_quantity: 0, unit: 'KG' },
  { product: 'Acrylic', lotno: '12975', demand_quantity: 110, received_quantity: 110, unit: 'KG' },
  { product: 'Rayon', lotno: '67384', demand_quantity: 130, received_quantity: 130, unit: 'KG' },
  { product: 'Spandex', lotno: '84920', demand_quantity: 70, received_quantity: 70, unit: 'KG' },
  { product: 'Bamboo', lotno: '32475', demand_quantity: 140, received_quantity: 0, unit: 'KG' },
  { product: 'Hemp', lotno: '74938', demand_quantity: 90, received_quantity: 90, unit: 'KG' },
  { product: 'Cashmere', lotno: '92348', demand_quantity: 100, received_quantity: 100, unit: 'KG' },
  { product: 'Denim', lotno: '56342', demand_quantity: 200, received_quantity: 200, unit: 'KG' },
  { product: 'Velvet', lotno: '19384', demand_quantity: 50, received_quantity: 50, unit: 'KG' },
];

// Purchase order supplier end
export const poInnerData = [
  {
    product: 'WIP LOT 20',
    quantity: 133.8,
    received: 133.8,
    undelivered: 0,
    billed: 133.8,
    uom: 'KG',
    packaging: 'Active',
    unit_price: 1.4,
    taxes: '12%',
    future_lot: 'id',
  },
  {
    product: 'WIP LOT 40',
    quantity: 133.8,
    received: 133.8,
    undelivered: 0,
    billed: 133.8,
    uom: 'KG',
    packaging: 'Active',
    unit_price: 1.4,
    taxes: '15%',
    future_lot: 'id',
  },
  {
    product: 'WIP LOT 80',
    quantity: 133.8,
    received: 133.8,
    undelivered: 0,
    billed: 133.8,
    uom: 'KG',
    packaging: 'Active',
    unit_price: 1.4,
    taxes: '10%',
    future_lot: 'id',
  },
];

// Bills list data
export const bill_data = [
  {
    invoice: 'BILL/001',
    invoice_date: '2024-01-01',
    due_date: '2024-01-15',
    status: 'Waiting for Process',
    amount_due: 1200.5,
  },
  {
    invoice: 'BILL/002',
    invoice_date: '2024-01-03',
    due_date: '2024-01-17',
    status: 'Completed',
    amount_due: 750.0,
  },
  {
    invoice: 'BILL/003',
    invoice_date: '2024-01-05',
    due_date: '2024-01-20',
    status: 'Pending',
    amount_due: 300.75,
  },
  {
    invoice: 'BILL/004',
    invoice_date: '2024-01-07',
    due_date: '2024-01-21',
    status: 'Waiting for Process',
    amount_due: 450.6,
  },
  {
    invoice: 'BILL/005',
    invoice_date: '2024-01-10',
    due_date: '2024-01-24',
    status: 'Waiting for Process',
    amount_due: 980.4,
  },
  {
    invoice: 'BILL/006',
    invoice_date: '2024-01-12',
    due_date: '2024-01-26',
    status: 'Pending',
    amount_due: 650.3,
  },
  {
    invoice: 'BILL/007',
    invoice_date: '2024-01-14',
    due_date: '2024-01-28',
    status: 'Paid',
    amount_due: 1000.0,
  },
  {
    invoice: 'BILL/008',
    invoice_date: '2024-01-16',
    due_date: '2024-01-30',
    status: 'Unpaid',
    amount_due: 540.0,
  },
  {
    invoice: 'BILL/009',
    invoice_date: '2024-01-18',
    due_date: '2024-02-01',
    status: 'Pending',
    amount_due: 820.25,
  },
  {
    invoice: 'BILL/010',
    invoice_date: '2024-01-20',
    due_date: '2024-02-03',
    status: 'Paid',
    amount_due: 1500.7,
  },
];

// Bills inner data
export const billsInnerData = [
  {
    product: 'Product A',
    quantity: 10,
    uom: 'pcs',
    price: 100.0,
    tax: '10%',
    tax_excl: 90.0,
  },
  {
    product: 'Product B',
    quantity: 20,
    uom: 'pcs',
    price: 200.0,
    tax: '15%',
    tax_excl: 180.0,
  },
  {
    product: 'Product C',
    quantity: 15,
    uom: 'pcs',
    price: 150.0,
    tax: '15%',
    tax_excl: 135.0,
  },
  {
    product: 'Product D',
    quantity: 30,
    uom: 'pcs',
    price: 300.0,
    tax: '12%',
    tax_excl: 264.0,
  },
  {
    product: 'Product E',
    quantity: 25,
    uom: 'pcs',
    price: 250.0,
    tax: '10%',
    tax_excl: 220.0,
  },
];

// Package list data
export const package_data = [
  {
    package_no: 'PKG001',
    box_count: 3,
    packaging_date: '2024-06-01',
    dispatch_date: '2024-06-05',
    status: 'dispatched',
  },
  {
    package_no: 'PKG002',
    box_count: 2,
    packaging_date: '2024-06-02',
    dispatch_date: '2024-06-06',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG003',
    box_count: 1,
    packaging_date: '2024-06-03',
    dispatch_date: '2024-06-07',
    status: 'dispatched',
  },
  {
    package_no: 'PKG004',
    box_count: 5,
    packaging_date: '2024-06-04',
    dispatch_date: '2024-06-08',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG005',
    box_count: 4,
    packaging_date: '2024-06-05',
    dispatch_date: '2024-06-09',
    status: 'dispatched',
  },
  {
    package_no: 'PKG006',
    box_count: 6,
    packaging_date: '2024-06-06',
    dispatch_date: '2024-06-10',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG007',
    box_count: 3,
    packaging_date: '2024-06-07',
    dispatch_date: '2024-06-11',
    status: 'dispatched',
  },
  {
    package_no: 'PKG008',
    box_count: 2,
    packaging_date: '2024-06-08',
    dispatch_date: '2024-06-12',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG009',
    box_count: 1,
    packaging_date: '2024-06-09',
    dispatch_date: '2024-06-13',
    status: 'dispatched',
  },
  {
    package_no: 'PKG010',
    box_count: 5,
    packaging_date: '2024-06-10',
    dispatch_date: '2024-06-14',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG011',
    box_count: 4,
    packaging_date: '2024-06-11',
    dispatch_date: '2024-06-15',
    status: 'dispatched',
  },
  {
    package_no: 'PKG012',
    box_count: 6,
    packaging_date: '2024-06-12',
    dispatch_date: '2024-06-16',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG013',
    box_count: 3,
    packaging_date: '2024-06-13',
    dispatch_date: '2024-06-17',
    status: 'dispatched',
  },
  {
    package_no: 'PKG014',
    box_count: 2,
    packaging_date: '2024-06-14',
    dispatch_date: '2024-06-18',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG015',
    box_count: 1,
    packaging_date: '2024-06-15',
    dispatch_date: '2024-06-19',
    status: 'dispatched',
  },
  {
    package_no: 'PKG016',
    box_count: 5,
    packaging_date: '2024-06-16',
    dispatch_date: '2024-06-20',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG017',
    box_count: 4,
    packaging_date: '2024-06-17',
    dispatch_date: '2024-06-21',
    status: 'dispatched',
  },
  {
    package_no: 'PKG018',
    box_count: 6,
    packaging_date: '2024-06-18',
    dispatch_date: '2024-06-22',
    status: 'ready for dispatch',
  },
  {
    package_no: 'PKG019',
    box_count: 3,
    packaging_date: '2024-06-19',
    dispatch_date: '2024-06-23',
    status: 'dispatched',
  },
  {
    package_no: 'PKG020',
    box_count: 2,
    packaging_date: '2024-06-20',
    dispatch_date: '2024-06-24',
    status: 'ready for dispatch',
  },
];

// Dispatch list data
export const dispatch_data = [
  {
    id: 1,
    shipment_no: 'SN123456',
    location: 'Warehouse A',
    shipment_date: '2024-06-01',
    box_count: 10,
    status: 'dispatched',
  },
  {
    id: 2,
    shipment_no: 'SN123457',
    location: 'Warehouse B',
    shipment_date: '2024-06-02',
    box_count: 15,
    status: 'ready for dispatch',
  },
  {
    id: 3,
    shipment_no: 'SN123458',
    location: 'Warehouse C',
    shipment_date: '2024-06-03',
    box_count: 20,
    status: 'dispatched',
  },
  {
    id: 4,
    shipment_no: 'SN123459',
    location: 'Warehouse A',
    shipment_date: '2024-06-04',
    box_count: 8,
    status: 'dispatched',
  },
  {
    id: 5,
    shipment_no: 'SN123460',
    location: 'Warehouse B',
    shipment_date: '2024-06-05',
    box_count: 12,
    status: 'ready for dispatch',
  },
  {
    id: 6,
    shipment_no: 'SN123461',
    location: 'Warehouse C',
    shipment_date: '2024-06-06',
    box_count: 18,
    status: 'dispatched',
  },
  {
    id: 7,
    shipment_no: 'SN123462',
    location: 'Warehouse A',
    shipment_date: '2024-06-07',
    box_count: 22,
    status: 'dispatched',
  },
  {
    id: 8,
    shipment_no: 'SN123463',
    location: 'Warehouse B',
    shipment_date: '2024-06-08',
    box_count: 17,
    status: 'ready for dispatch',
  },
  {
    id: 9,
    shipment_no: 'SN123464',
    location: 'Warehouse C',
    shipment_date: '2024-06-09',
    box_count: 25,
    status: 'dispatched',
  },
  {
    id: 10,
    shipment_no: 'SN123465',
    location: 'Warehouse A',
    shipment_date: '2024-06-10',
    box_count: 30,
    status: 'dispatched',
  },
];
