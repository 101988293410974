import { useEffect } from 'react';

export const useIntersectionObserver = ({ target, onIntersect, enabled }) => {
  useEffect(() => {
    if (!enabled) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
        });
      },
      { threshold: 0.5 },
    );

    observer.observe(target.current);

    return () => {
      observer.disconnect();
    };
  }, [target, onIntersect, enabled]);
};
