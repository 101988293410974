import React, { useState, useEffect } from 'react';
import { DropDownArrow2 } from '../../assets/icons';

export const DropDownInput = ({ dropdownMenu, value, onChange }) => {
  const [filter, setFilter] = useState(value || dropdownMenu[0]);

  useEffect(() => {
    setFilter(value);
  }, [value]);

  const handleDropdownMenu = (item) => {
    setFilter(item);
    onChange(item);
  };

  return (
    <div className="custom-dropdown-3 d-flex align-items-center justify-content-center">
      <div className="dropdown w-100">
        <button
          className="w-100 btn dropdown-toggle bg-white d-flex align-items-center justify-content-between"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {filter}
          <DropDownArrow2 />
        </button>
        <ul className="dropdown-menu bg-white border-0 py-0" aria-labelledby="dropdownMenuButton1">
          {dropdownMenu.map((item, index) => (
            <li
              key={index}
              onClick={() => handleDropdownMenu(item)}
              className={`${item === filter ? 'bg-dark-subtle ' : ''}`}
            >
              <div className="dropdown-item d-flex align-items-center justify-content-start">
                {item}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
