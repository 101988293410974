import { ERROR } from '../constants';

// Function to set a value in local storage
const setLocalStorageItem = (key, value) => {
  if (typeof key !== 'string' || value === undefined) {
    console.error(ERROR.INVALID_LOCAL_STORAGE_KEY_OR_VALUE);
    return null;
  }

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error setting localStorage item:', error);
  }
};

// Function to get a value from local storage
const getLocalStorageItem = (key) => {
  if (typeof key !== 'string') {
    console.error(ERROR.INVALID_LOCAL_STORAGE_KEY_OR_VALUE);
    return null;
  }

  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error('Error getting localStorage item:', error);
    return null;
  }
};

export { setLocalStorageItem, getLocalStorageItem };
