import React from 'react';
import BarcodeReader from 'react-barcode-reader';

export const Scanner = ({ setScanData }) => {
  const handleScan = (data) => {
    setScanData(data);
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <div className="me-5 spinner-border text-secondary" role="status">
        <span className="sr-only"></span>
      </div>
      <BarcodeReader onError={handleError} onScan={handleScan} />
    </div>
  );
};
