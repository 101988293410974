export function packageDataCompiler(data = []) {
  let finalData = [];
  data.forEach((obj) => {
    let packageData = {
      BLP: obj.name,
      SKU: obj.quant_ids[0]?.product_id?.default_code,
      Batch: obj.quant_ids?.[0]?.lot_id?.name,
      SKU_Details: obj.quant_ids?.map((iobj) => ({
        SKU: iobj.product_id?.display_name,
        'Batch No.': iobj.lot_id?.name,
        Quantity: iobj.quantity + ' Unit',
      })),
      data: obj,
      box_type:
        obj.quant_ids.length > 1 &&
        obj.quant_ids.every(
          (quant) => quant.product_id.default_code !== obj.quant_ids[0].product_id.default_code,
        )
          ? 'Mix Box'
          : 'Single Box',
    };
    finalData.push(packageData);
  });
  return finalData;
}

export function packageDetailsBox(po) {
  let detailBoxData = [
    { name: 'Shipping Weight', col: 1, data: po?.weight },
    { name: 'Company Name', col: 1, data: 'Shakawear' },
    { name: 'Location', col: 1, data: po?.location_id?.display_name },
    { name: 'Additional reference', col: 1, data: '' },
    { name: 'GTIN Liscence Plate', col: 1, data: po?.gtin || 'N/A' },
    { name: 'Box Liscence Plate', col: 2, data: po?.name },
    { name: 'AB Style', col: 2, data: 'N/A' },
    {
      name: 'AB Color',
      col: 2,
      data: po?.quant_ids[0]?.product_id?.product_template_attribute_value_ids[0]?.name,
    },
    { name: 'AB Color Code', col: 2, data: 'N/A' },
    {
      name: 'AB Size',
      col: 2,
      data: po?.quant_ids[0]?.product_id?.product_template_attribute_value_ids[1]?.name,
    },
  ];
  return detailBoxData;
}
