import React from 'react';
const BoxIcon = (props) => (
  <svg
    width={39}
    height={39}
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={19.5} cy={19.5} r={19.5} fill="#D9D9D9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16.6755L31.0928 12.3375L20 8L8.90674 12.3375L20 16.6755Z"
      fill="#6D6D6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7596 31.0002V17.0887L8.5 12.6855V26.5975L19.7596 31.0002Z"
      fill="#6D6D6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2402 17.0887V31.0002L31.4999 26.597V12.6855L20.2402 17.0887Z"
      fill="#6D6D6D"
    />
    <path d="M12.8703 16.0142L10.375 14.9688V17.7566L12.8703 18.802V16.0142Z" fill="white" />
    <path d="M12.8703 19.8482L10.375 18.8027V21.5905L12.8703 22.636V19.8482Z" fill="white" />
    <path d="M12.8703 23.6821L10.375 22.6367V25.4245L12.8703 26.47V23.6821Z" fill="white" />
    <path d="M17.8611 25.772L15.3657 24.7266V27.5144L17.8611 28.5598V25.772Z" fill="white" />
    <path d="M17.8611 18.106L15.3657 17.0605V19.8484L17.8611 20.8938V18.106Z" fill="white" />
    <path d="M17.8611 21.94L15.3657 20.8945V23.6823L17.8611 24.7278V21.94Z" fill="white" />
  </svg>
);
export default BoxIcon;
