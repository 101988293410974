import React from 'react';
const Edit1 = (props) => (
  <svg
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_388_317)">
      <path d="M8.87695 0.927734V5.36501H13.3144L8.87695 0.927734Z" fill="#727272" />
      <path
        d="M6.93097 16.5542V15.5649C6.93097 14.98 7.15874 14.4303 7.57221 14.0168L8.4403 13.1488H4.25517C3.85216 13.1488 3.52544 12.822 3.52544 12.419C3.52544 12.016 3.85216 11.6893 4.25517 11.6893H9.89973L13.6369 7.95201C13.6709 7.9181 13.706 7.8859 13.7417 7.85452V6.82423H8.14705C7.74404 6.82423 7.41731 6.4975 7.41731 6.09449V0.5H1.33618C0.933172 0.5 0.606445 0.826727 0.606445 1.22974V15.8244C0.606445 16.2275 0.933172 16.5542 1.33618 16.5542H6.93097ZM4.25517 8.77034H10.0931C10.4961 8.77034 10.8228 9.09706 10.8228 9.50007C10.8228 9.90308 10.4961 10.2298 10.0931 10.2298H4.25517C3.85216 10.2298 3.52544 9.90308 3.52544 9.50007C3.52544 9.09706 3.85216 8.77034 4.25517 8.77034Z"
        fill="#727272"
      />
      <path
        d="M17.9067 11.1897L15.7013 8.98427C15.5588 8.84178 15.372 8.77051 15.1853 8.77051C14.9986 8.77051 14.8118 8.84178 14.6693 8.98427L13.742 9.91162L8.60439 15.0493C8.46754 15.1861 8.39062 15.3717 8.39062 15.5652V16.5545V17.7706C8.39062 18.1736 8.71735 18.5004 9.12036 18.5004H11.3257C11.5193 18.5004 11.7049 18.4235 11.8417 18.2866L17.9067 12.2217C18.0436 12.0849 18.1205 11.8993 18.1205 11.7057C18.1205 11.5122 18.0436 11.3266 17.9067 11.1897Z"
        fill="#02878F"
      />
    </g>
    <defs>
      <clipPath id="clip0_388_317">
        <rect width={18} height={18} fill="white" transform="translate(0.363281 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default Edit1;
