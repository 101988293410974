import React from 'react';
const Bills = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4346 3.05859L4.56467 3.05974C4.56376 3.05974 4.563 3.06026 4.56209 3.06026C2.90594 3.06055 1.5625 4.40409 1.5625 6.06019V8.81802C1.5625 9.41178 2.04687 9.89615 2.64063 9.89615H6.07681V22.7493C6.07681 23.0472 6.24619 23.3192 6.51398 23.4504C6.78024 23.582 7.09991 23.55 7.33566 23.3677L9.49707 21.6987L11.6554 23.3673C11.9362 23.5847 12.3291 23.5851 12.6114 23.3669L14.7644 21.6995L16.9167 23.3669C17.1974 23.5851 17.5926 23.5851 17.8734 23.3669L20.0256 21.6999L22.1741 23.3665C22.3145 23.4749 22.4831 23.5305 22.6532 23.5305C22.7699 23.5305 22.8882 23.5042 22.9965 23.4508C23.2643 23.3196 23.4344 23.0476 23.4344 22.7493L23.4375 6.0619C23.4375 4.40594 22.0901 3.05859 20.4346 3.05859ZM6.07815 8.33365H3.125V6.06019C3.125 5.27112 3.77345 4.62271 4.64063 4.62271C5.40924 4.62534 6.08153 5.26406 6.07815 6.06019V8.33365ZM18.7031 16.5055H10.8125C10.3828 16.5055 10.0313 16.1539 10.0313 15.7242C10.0313 15.2946 10.3828 14.943 10.8125 14.943H18.7031C19.1328 14.943 19.4844 15.2946 19.4844 15.7242C19.4844 16.1539 19.1328 16.5055 18.7031 16.5055ZM18.7031 11.6461H10.8125C10.3828 11.6461 10.0313 11.2946 10.0313 10.8649C10.0313 10.4352 10.3828 10.0836 10.8125 10.0836H18.7031C19.1328 10.0836 19.4844 10.4352 19.4844 10.8649C19.4844 11.2946 19.1328 11.6461 18.7031 11.6461Z"
      fill="#707070"
    />
  </svg>
);
export default Bills;
