import React from 'react';
export const PopUpBox = ({ openPopUpBox, children }) => {
  return (
    <div
      className={`modal custom-modal-doc-cato fade ${openPopUpBox ? 'show' : ''}`}
      aria-hidden={!openPopUpBox}
      tabIndex={-1}
      style={{ display: openPopUpBox ? 'block' : 'none' }}
    >
      <div className="modal-dialog w-100 p-0">
        <div className="modal-content p-1 ">
          {/* <div className="d-flex m-1 mx-4"> */}
          <div>{children}</div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
