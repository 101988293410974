import { toast, Flip } from 'react-toastify';
import { POPUP_TYPE } from '../constants';
export const popup = (
  type,
  message,
  position = 'top-center',
  style = {},
  icon = true,
  hideProgressBar = false,
  delay = 1000,
) => {
  const toast_style = {
    position,
    autoClose: 4000,
    hideProgressBar,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    pauseOnFocusLoss: false,
    transition: Flip,
    style,
    icon,
    delay,
    className: 'my-toast',
  };

  switch (type) {
    case POPUP_TYPE.SUCCESS:
      return toast.success(message, toast_style);
    case 'info':
      return toast.info(message, toast_style);
    case POPUP_TYPE.ERR:
      return toast.error(message, toast_style);
    default:
      return toast(message, toast_style);
  }
};
