import React from 'react';
const GetStarted = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_246_268)">
      <path
        d="M24.7852 1.39791L23.7495 0.362124C23.5336 0.146109 23.2063 0.0869293 22.9286 0.212906L17.3654 2.66881C17.1465 2.76842 16.9906 2.96876 16.9477 3.20489C16.9051 3.44166 16.9805 3.68385 17.1505 3.85382L21.2936 7.99692C21.4636 8.16689 21.7058 8.24224 21.9425 8.19971C22.1787 8.15679 22.379 8.00098 22.4786 7.78198L24.9345 2.21871C25.0604 1.94103 25.0013 1.61383 24.7852 1.39791Z"
        fill="#707070"
      />
      <path
        d="M4.73145 16.2729L0.642589 20.3618C-0.214196 21.2186 -0.214196 22.6124 0.642589 23.4691L1.67833 24.5049C2.53512 25.3617 3.92891 25.3617 4.78565 24.5049L8.87451 20.416L4.73145 16.2729ZM4.27496 21.9185C3.98868 22.2048 3.52535 22.2048 3.23912 21.9185C2.95294 21.6323 2.95294 21.1689 3.23912 20.8828L5.25645 18.8655C5.54273 18.5791 6.00601 18.5791 6.29219 18.8655C6.57847 19.1518 6.57847 19.615 6.29219 19.9012L4.27496 21.9185Z"
        fill="#707070"
      />
      <path
        d="M11.9818 17.3087L7.83868 13.1656C7.26763 12.5945 6.33814 12.5945 5.76715 13.1656C5.19611 13.7366 5.19611 14.6661 5.76715 15.2371L9.91026 19.3802C10.4812 19.9513 11.4107 19.9513 11.9818 19.3802C12.5528 18.8093 12.5528 17.8797 11.9818 17.3087Z"
        fill="#707070"
      />
      <path
        d="M19.2222 7.99687L17.1506 5.92529L11.464 11.6119C10.8919 11.0399 9.96455 11.0399 9.39243 11.6119L8.87451 12.1298L13.0176 16.2729L13.5355 15.755C14.1076 15.1829 14.1076 14.2555 13.5355 13.6835L19.2222 7.99687Z"
        fill="#707070"
      />
      <path
        d="M23.7149 17.6455C22.5259 16.4565 21.0961 16.2008 19.4556 16.494L16.1258 13.1646L15.3307 13.9597C15.5925 14.9436 15.3379 16.0238 14.571 16.7907L14.0542 17.3075L16.3457 19.5989C16.1147 20.8918 16.1364 21.9885 16.9394 23.1779C17.8661 24.551 19.4879 25.3315 21.187 25.0976C21.4679 25.059 21.7025 24.8641 21.7928 24.5954C21.8831 24.3266 21.8135 24.0299 21.613 23.8294L20.6057 22.8239V20.7526H22.6792L23.6809 21.7543C23.8818 21.9552 24.1793 22.0243 24.4483 21.9333C24.7173 21.8423 24.9115 21.6066 24.9493 21.325C25.1282 19.9928 24.6923 18.6224 23.7149 17.6455Z"
        fill="#707070"
      />
      <path
        d="M8.64437 5.67982C8.93504 4.05644 8.69969 2.62832 7.49286 1.4206C6.67172 0.599511 5.57308 0.147461 4.39936 0.147461C4.20175 0.147461 4.00624 0.160352 3.81327 0.185889C3.53149 0.223291 3.2956 0.417724 3.20448 0.687011C3.11337 0.9562 3.18295 1.25386 3.38393 1.45483L4.39545 2.45629V4.54194H2.31376L1.30976 3.52387C1.10922 3.32333 0.812204 3.25395 0.543357 3.34443C0.274607 3.43491 0.0802229 3.66977 0.0415999 3.95077C-0.181544 5.57562 0.522507 7.22635 1.95898 8.19608C3.15292 9.00047 4.25614 9.02039 5.53944 8.78905L7.84027 11.0936L8.35731 10.5766C9.1242 9.8097 10.2043 9.55526 11.1882 9.81697L11.983 9.02215L8.64437 5.67982Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_246_268">
        <rect width={25} height={25} fill="white" transform="translate(0 0.147461)" />
      </clipPath>
    </defs>
  </svg>
);
export default GetStarted;
