import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const vendorPackage = async ({ data = {} }) => {
  const url = API.PACKAGE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const mixBoxPackage = async ({ data = {} }) => {
  const url = API.MIX_BOX_PACKAGE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const submitSkuMixBoxPackage = async ({ data = {} }) => {
  const url = API.SUBMIT_SKU_MIX_BOX_PACKAGE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const printLabelPackage = async ({ data = {} }) => {
  const url = API.PRINT_LABEL_PACKAGE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};


