import React from 'react';
import AppRouter from './AppRouter';
import { useSelector } from 'react-redux';
import { ErrorBoundary, Loader } from './components/common';
import { Toaster } from 'react-hot-toast';

function App() {
  const isLoaderRequired = useSelector((state) => state.loader);
  return (
    <ErrorBoundary>
      <Toaster position="top-center" reverseOrder={false} containerClassName="toaster-container" />
      <AppRouter />
      {isLoaderRequired && <Loader />}
    </ErrorBoundary>
  );
}

export default App;
