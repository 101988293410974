import React from 'react';

const DetailTable = ({ title, columns, data }) => {
  return (
    <div className="data-content">
      <div className="w-100 editing-container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="edit-container py-4 px-lg-5 px-4 table-new-div bg-white data-box w-100 h-100">
            <h5 className="text-center m-2">{title}</h5>
            <div className="w-100 table-3">
              <table className="w-100">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="inner-table-tbody">
                  {data
                    ? data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {columns.map((column, colIndex) => (
                            <td key={colIndex}>{row[column]}</td>
                          ))}
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTable;
