import React from 'react';
import { useState } from 'react';

export const Loader = ({ height = '100vh', width = '100vw' }) => {
  const [interval, setLoaderCount] = useState(0);
  const loaderCount = () => {
    if (interval === 0) {
      let myInterval = setInterval(() => {
        interval < 12 ? setLoaderCount((prev) => prev + 2) : setLoaderCount(11);
      }, 90);

      setTimeout(() => {
        clearInterval(myInterval);
      }, 1250);
    }
  };
  loaderCount();

  return (
    <div
      className="loader d-flex justify-content-center align-items-center"
      style={{ height: height, width: width }}
    >
      <div className="spinner-box ">
        <div
          className={`circle-div c1 ${interval > 0 ? 'spinner-grow' : ''}   text-secondary`}
          role="status"
        ></div>
        <div
          className={`circle-div c2 ${interval > 2 ? 'spinner-grow' : ''}  text-secondary`}
          role="status"
        ></div>
        <div
          className={`circle-div c3 ${interval > 4 ? 'spinner-grow' : ''}  text-secondary`}
          role="status"
        ></div>
        <div
          className={`circle-div c4 ${interval > 6 ? 'spinner-grow' : ''}  text-secondary`}
          role="status"
        ></div>
        <div
          className={`circle-div c5 ${interval > 8 ? 'spinner-grow' : ''}  text-secondary`}
          role="status"
        ></div>
        <div
          className={`circle-div c6 ${interval > 10 ? 'spinner-grow' : ''}  text-secondary`}
          role="status"
        ></div>
      </div>
    </div>
  );
};
