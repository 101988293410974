import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import {
  TITLES,
  ROUTES,
  FABRIC_DISPATCH_INNER_COLUMN,
  BUTTON,
  POPUP_TYPE,
  POPUP_MSG,
} from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  dispatchDetailsBox,
  fabricDispatchProductCompiler,
} from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { updateReceiptQty, vendorReceipt } from '../../../service/receipt';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { Button, PopUpBox } from '../../shared';
import { markDispatched } from '../../../service';

export const FabricDispatchDetail = () => {
  const { state } = useLocation(location?.state);
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState('');
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  let filteredColumn= (state?.dispatch_status==='dispatched' || state?.state==='done')?FABRIC_DISPATCH_INNER_COLUMN.filter((col) => col.name !== 'Action'): FABRIC_DISPATCH_INNER_COLUMN
  const getProductsList = useDebounce(() => {
    setReload(false);
    vendorReceipt({
      data: { receipt_ids: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setProductData(fabricDispatchProductCompiler(response.data[0]));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, [reload]);

  const updateReceipt = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const submitDispatch = () => {
    dispatch(setLoaderVisibility(true));
    markDispatched({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  };

  console.log(productData);

  const enableDispatch = () => {
    if (productData?.length) {
      return productData?.every((move) => ((move?.lotno !== undefined) || (move.lotno!==false)));
    }
  };
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: ROUTES.FABRIC_DISPATCH_DETAIL },
        ]}
      />
      <div className="scroll-div">
        {state?.dispatch_status === 'draft' && (
          <div className="d-flex justify-content-end align-items-center">
            <Button
            disabled={enableDispatch()}
              className={`blue-btn d-flex align-items-center ${enableDispatch()===true ? 'disabled':''} justify-content-center px-4 mb-3`}
              clickHandler={submitDispatch}>
              {BUTTON.DISPATCH}
            </Button>
          </div>
        )}
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <Table
          columns={filteredColumn}
          initialData={productData}
          PAGE_SIZE={4}
          colFilter={false}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      </div>
      {popUp && (
        <PopUpBox openPopUpBox={popUp}>
          <QtyUpdate apiCall={updateReceipt} setOpenPopUpBox={setPopUp} data={popUpData} />
        </PopUpBox>
      )}
    </>
  );
};

const QtyUpdate = ({ apiCall, setOpenPopUpBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenPopUpBox(false);
      const apiData = {
        move_id: data.move_id,
        done_qty: +doneQty,
      };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);

  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 "> {POPUP_MSG.ENTER_QTY} </h4>
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.producing_quantity || ''}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
