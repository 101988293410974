import React, { useEffect, useState } from 'react';
import { BlpTiles, SearchBar, TitleBar } from '../../common';
import {
  BUTTON,
  DISPLAY_TEXTS,
  PKJ_STATUS,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
} from '../../../constants';
import { Button, PopUpBox } from '../../shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { StatusBox } from '../manufacturing/clothManufacturing/StatusBox';
import { Scanner } from '../../common/Scanner';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import {
  getPreScannedPackages,
  vendorDeleteDispatch,
  vendorSubmitDispatch,
  addPackageToDispatch,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { shipmentDataCompiler } from '../../../utils/dataCompiler/dispatchDataCompiler';
import { FabricDispatchDetail } from './FabricDispatchDetail';

export const DispatchDetail = () => {
  const { state } = useLocation(location?.state);
  return <>{state.dyeing_shipment ? <FabricDispatchDetail /> : <DispatchDetailNonDyeing />}</>;
};

const DispatchDetailNonDyeing = () => {
  const navigate = useNavigate();
  const onTileClick = (to, data) => {
    navigate(to, { state: data });
  };
  const dispatch = useDispatch();
  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [preScannedPackages, setPreScannedPackages] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [validate, setValidate] = useState(false);
  const { state } = useLocation(location?.state);

  const deleteDispatch = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorDeleteDispatch({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const getAlreadyScannedPackages = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    getPreScannedPackages({
      data: { id: state?.id },
    })
      .then((response) => {
        response.data.length > 0
          ? setPreScannedPackages(shipmentDataCompiler(response.data[0]))
          : setPreScannedPackages(response.message);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const startScanning = () => {
    setScanning((pre) => !pre);
  };

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getAlreadyScannedPackages();
  }, []);

  useEffect(() => {
    if (Array.isArray(preScannedPackages) && preScannedPackages.length > 0) {
      const initialTiles = preScannedPackages.map((tile) => {
        const isUniformSKU = tile.SKU_Details.every((sku) => sku.SKU === tile.SKU_Details[0].SKU);
        const tileClass = isUniformSKU ? 'blue-shade' : 'pink-shade';
        return { ...tile, tileClass };
      });
      setTilesToDisplay(initialTiles);
    }
  }, [preScannedPackages]);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      addPackageToDispatch({
        data: { dispatch_id: state?.id, package_name: scanData, type: 'add' },
      }).then((response) => {
        response.data.length > 0
          ? popup(POPUP_TYPE.SUCCESS, response.message)
          : popup(POPUP_TYPE.ERR, response.message);
      });
      setScanData('');
      dispatch(setLoaderVisibility(false));
      getAlreadyScannedPackages();
    }
  }, [scanData]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: ROUTES.CREATE_DISPATCH },
        ]}
      />
      <div className="bg-white p-2 align-items-center package-top-info border-def-up border-def-down">
        <div className="d-flex align-items-center justify-content-end">
          <StatusBox status={PKJ_STATUS} />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3 top-header-set">
          <SearchBar />
          <div className="d-flex justify-content-end align-items-center">
            <h4 className="me-3 mb-4">{DISPLAY_TEXTS.CREATE_DISPATCH[1]}</h4>
            <div className="number-count mb-4 d-flex align-items-center justify-content-center">
              {tilesToDisplay.length}
            </div>
          </div>
        </div>

        {state?.state === 'draft' && (
          <div className="d-flex justify-content-between">
            <Button
              clickHandler={deleteDispatch}
              className="blue-btn d-flex align-items-center justify-content-center px-4 mx-2">
              {BUTTON.CANCEL_DISPATCH}
            </Button>
            <div className="d-flex justify-content-end">
              {scanning && <Scanner setScanData={setScanData} />}
              <Button
                clickHandler={startScanning}
                className="white-btn d-flex align-items-center justify-content-center px-4 mx-2">
                {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX}
              </Button>
              <Button
                className="blue-btn d-flex align-items-center justify-content-center px-4"
                clickHandler={() => setValidate(true)}>
                {BUTTON.SUBMIT}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white p-3 pt-0 mt-2 package-titles-info border-def-up border-def-down">
        <div className="row custom-row">
          {tilesToDisplay.map((tile, index) => (
            <BlpTiles
              key={index}
              tile={tile}
              clickHandler={onTileClick}
              showLoader={scanning}
              to={ROUTES.DISPATCH_BLP_OVERVIEW}
            />
          ))}
        </div>
      </div>
      {validate && (
        <PopUpBox openPopUpBox={validate}>
          <DispatchSubmitValidate setOpenPopUpBox={setValidate} id={state?.id} />
        </PopUpBox>
      )}
    </>
  );
};

const DispatchSubmitValidate = ({ setOpenPopUpBox, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closePopUp = () => {
    setOpenPopUpBox(false);
  };

  const handleSubmitDispatch = () => {
    setOpenPopUpBox(false);
    dispatch(setLoaderVisibility(true));
    vendorSubmitDispatch({
      data: { dispatch_id: id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleSaveAsDraft = () => {
    popup('success', 'Dispatch Saved as Draft');
    navigate(ROUTES.DISPATCH);
  };

  return (
    <>
      <div className="d-flex m-1">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className="text-center text-special mb-3">
            <u>NOTE</u>
          </h4>
          <p className="text-center text-special placeholder-color">
            {POPUP_MSG.SUBMIT_DISPATCH_WARNING}
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleSubmitDispatch}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.SUBMIT}
          </Button>
          <Button
            clickHandler={handleSaveAsDraft}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            Save as Draft
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
