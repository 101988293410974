import React from 'react';
const EyeIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50705 9.57762C6.12371 9.19496 5.89038 8.67496 5.89038 8.09162C5.89038 6.92296 6.83171 5.98096 7.99971 5.98096C8.57771 5.98096 9.10971 6.21496 9.48638 6.59762"
        stroke="#738DA6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0698 8.46582C9.91509 9.32582 9.23775 10.0045 8.37842 10.1605"
        stroke="#738DA6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.43634 11.648C3.37834 10.8173 2.48234 9.60399 1.83301 8.09132C2.48901 6.57199 3.39101 5.35199 4.45567 4.51466C5.51367 3.67732 6.73434 3.22266 7.99967 3.22266C9.27234 3.22266 10.4923 3.68399 11.557 4.52732"
        stroke="#738DA6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.965 5.99365C13.4236 6.60299 13.827 7.30632 14.1663 8.09099C12.855 11.129 10.5376 12.959 7.99965 12.959C7.42431 12.959 6.85698 12.8657 6.31165 12.6837"
        stroke="#738DA6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2579 2.83301L2.74194 13.349"
        stroke="#738DA6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EyeIcon;
