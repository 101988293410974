import React, { useEffect, useState } from 'react';
import { setLoaderVisibility } from '../../../redux';
import { useDispatch } from 'react-redux';
import { DropDown, SearchBar, Table, TitleBar } from '../../common';
import { PO_COLUMN, ROUTES, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { poDataCompiler, popup, getSortingArray } from '../../../utils/';
import { vendorPurchase } from '../../../service';
import { useDebounce } from '../../../hooks';
import { Button } from '../../shared';

export const PoContent = () => {
  const [poData, setPoData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(PO_COLUMN[4].name);
  const [sort2, setSort2] = useState(PO_COLUMN[5].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [sortingArray2, setSortingArray2] = useState([]);
  const dispatch = useDispatch();

  const getPoList = useDebounce((data = {}) => {
    vendorPurchase({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPoData(response.data);
          if (!sortingArray1.includes(sort1) && !sortingArray2.includes(sort2)) {
            setSortingArray1(
              getSortingArray({
                column: PO_COLUMN[4],
                data: response.data,
                compiler: poDataCompiler,
              }),
            );
            setSortingArray2(
              getSortingArray({
                column: PO_COLUMN[5],
                data: response.data,
                compiler: poDataCompiler,
              }),
            );
          }
        } else {
          setPoData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
  
  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPoList(data);
    } else if (!searchTerm && !Array.isArray(poData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPoList(data);
    }
  }, [search, searchTerm]);

  const resetFilter = () => {
    setSort2(PO_COLUMN[5].name);
    setSort1(PO_COLUMN[4].name);
    dispatch(setLoaderVisibility(true));
    getPoList();
  };

  // to load data on page load
  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getPoList();
  }, []);

  useEffect(() => {
    let data = {};
    dispatch(setLoaderVisibility(true));
    if (sortingArray1.length > 1 || sortingArray2.length > 1) {
      data = {
        receipt_status: `${sortingArray2.includes(sort2) ? sort2.toLowerCase() : ''}`,
        invoice_status: `${sortingArray1.includes(sort1) ? sort1.toLowerCase() : ''}`,
      };
      getPoList(data);
    }
  }, [sort1, sort2]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.PURCHASE_ORDER, route: ROUTES.PURCHASE_ORDER }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2}></DropDown>
          {(sortingArray2.includes(sort2) || sortingArray1.includes(sort1)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(poData) ? (
        <Table
          columns={PO_COLUMN}
          initialData={poData}
          PAGE_SIZE={10}
          colFilter={true}
          to={`${ROUTES.PURCHASE_ORDER}`}
          compiler={{ use: true, tool: poDataCompiler }}
        />
      ) : (
        <div>
          <h5 className="px-1 py-5">{poData}</h5>
        </div>
      )}
    </>
  );
};
