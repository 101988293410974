import React from 'react';
const Manufacturing = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25 13.7026V23.84C25 24.1795 24.7247 24.4548 24.3852 24.4548H0.614755C0.275287 24.4548 0 24.1795 0 23.84V16.6671C0 16.434 0.131803 16.221 0.340328 16.117L6.28292 13.1525C6.47349 13.0575 6.69972 13.0678 6.88071 13.1798C7.06182 13.2918 7.1721 13.4897 7.1721 13.7026V15.6734L10.8111 13.8581V7.8542C10.8111 7.51473 11.0864 7.23945 11.4258 7.23945H12.5415C12.881 7.23945 13.1563 7.51473 13.1563 7.8542V15.6527L17.2162 13.6275L17.4675 2.73538C17.4753 2.40145 17.7481 2.13477 18.0822 2.13477H20.7621C21.0961 2.13477 21.369 2.40145 21.3768 2.73538L21.6455 14.3824L24.1108 13.1527C24.3014 13.0576 24.5275 13.0679 24.7086 13.1799C24.8897 13.292 25 13.4897 25 13.7026ZM4.31288 19.4796H2.64996V21.8164H4.31288V19.4796ZM8.81596 19.4796H7.15304V21.8164H8.81596V19.4796ZM13.3192 19.4796H11.6562V21.8164H13.3192V19.4796ZM17.8224 19.4796H16.1595V21.8164H17.8224V19.4796Z"
      fill="#707070"
    />
  </svg>
);
export default Manufacturing;
