import React from 'react';
import { dateFormatChanger } from '../../../utils';

export const MfgTable=({ column, data, clickHandler })=> {
  return (
    <table className=" w-100">
      <thead>
        <tr>
          {column.map((col, index) => (
            <th key={index} className="text-center">
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            className="text-center"
            key={rowIndex}
            style={{ cursor: 'pointer', color: 'black', fontSize: '12px', fontWeight: '600' }}
          >
            {column.map((column, colIndex) => (
              <td
                onClick={
                  column.isClickable
                    ? (e) => {
                        clickHandler({ row, e });
                      }
                    : () => {}
                }
                key={colIndex}
                className={column.classNameTD}
              >
                {column.isCustom
                  ? column.customFormatter(
                      column.dataField.reduce((acc, field) => {
                        acc[field] = row[field];
                        return acc;
                      }, {}),
                    )
                  : column.isDate
                    ? dateFormatChanger(row[column.dataField])
                    : row[column.dataField]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

