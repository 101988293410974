import React from 'react';
import { DatePickerBox } from '../../components/common';
import { BUTTON } from '../../constants';
export function poDataCompiler(data = []) {
  let finalData = [];
  data.map((obj) => {
    let poData = {
      url: obj.name.replaceAll('/', '_'),
      name: obj.name,
      contact: obj.partner_id.name,
      schedule_date: obj.effective_date || '-',
      qty: obj.order_line.reduce((acc, cum) => {
        acc += cum.product_qty;
        return acc;
      }, 0),
      received_qty: obj.order_line.reduce((acc, cum) => {
        acc += cum.qty_received;
        return acc;
      }, 0),
      qty_status: obj.receipt_status,
      amount: obj.amount_total,
      origin: obj.origin || '-',
      invoice_status: obj.invoice_status,
      data: obj,
    };
    finalData.push(poData);
  });
  return finalData;
}
export function poDetailsCompiler(data = []) {
  let finalData = [];
  data.map((obj) => {
    let poDetailsData = {
      product: obj.product_id.display_name,
      qty: obj.product_qty,
      received: obj.qty_received,
      balance: obj.product_qty - obj.qty_received,
      billed: obj.qty_invoiced,
      uom: obj.product_uom.name,
      unit_price: obj.price_unit,
      tax: 'N/A',
    };
    finalData.push(poDetailsData);
  });
  return finalData;
}

export function poDetailsBox(po) {
  let detailBoxData = [
    { name: 'Vendor', col: 1, data: po?.partner_id?.name },
    { name: 'Material Source PO', col: 1, data: 'N/A' },
    { name: 'Deliver To', col: 1, data: po?.picking_type_id?.name },
    { name: 'Purchase Agreement', col: 1, data: po?.state },
    { name: 'Confirmation Date/Time', col: 2, data: po?.create_date },
    { name: 'Receipt Date/Time', col: 2, data: po?.date_approve },
    { name: 'Effective Date/Time', col: 2, data: po?.effective_date || 'N/A' },
    {
      name: 'Bill Date',
      col: po?.createBill ? 2 : '',
      isCustom: po?.createBill,
      view: function ({ value, setValue, clickHandler, showSave }) {
        return (
          <div key={po?.name} className="row mb-3">
            <div className="col-6 title">{'Bill Date'}</div>
            <div className="col-6 d-flex align-items-center gap-3">
              <DatePickerBox
                value={value}
                setValue={setValue}
                clickHandler={clickHandler}
                showSave={showSave}
                btnName={BUTTON.SAVE}
              />
            </div>
          </div>
        );
      },
    },
  ];
  return detailBoxData;
}
