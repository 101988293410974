export const Manufacturing_List = [
  {
    lot_no: 111111,
    SKU: '112001',
    total_qty: 8400,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 123456,
    SKU: '112002',
    total_qty: 1700,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },

      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 135801,
    SKU: '112003',
    total_qty: 5000,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 148146,
    SKU: '112004',
    total_qty: 6400,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 160491,
    SKU: '112005',
    total_qty: 2200,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 172836,
    SKU: '112006',
    total_qty: 6800,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 185181,
    SKU: '112007',
    total_qty: 8300,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 197526,
    SKU: '112008',
    total_qty: 9200,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 209871,
    SKU: '112009',
    total_qty: 6700,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 222216,
    SKU: '112010',
    total_qty: 7400,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 234561,
    SKU: '112011',
    total_qty: 3700,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 246906,
    SKU: '112012',
    total_qty: 3800,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 259251,
    SKU: '112013',
    total_qty: 4100,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 271596,
    SKU: '112014',
    total_qty: 3100,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 283941,
    SKU: '112015',
    total_qty: 7600,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 296286,
    SKU: '112016',
    total_qty: 1100,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 308631,
    SKU: '112017',
    total_qty: 8200,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 320976,
    SKU: '112018',
    total_qty: 4000,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 333321,
    SKU: '112019',
    total_qty: 6900,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 345666,
    SKU: '112020',
    total_qty: 2700,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 358011,
    SKU: '112021',
    total_qty: 8200,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 370356,
    SKU: '112022',
    total_qty: 7000,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 382701,
    SKU: '112023',
    total_qty: 3000,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 395046,
    SKU: '112024',
    total_qty: 4300,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 407391,
    SKU: '112025',
    total_qty: 2900,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 419736,
    SKU: '112026',
    total_qty: 1400,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 432081,
    SKU: '112027',
    total_qty: 8400,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 444426,
    SKU: '112028',
    total_qty: 8900,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 456771,
    SKU: '112029',
    total_qty: 9700,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 469116,
    SKU: '112030',
    total_qty: 7100,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },

  {
    lot_no: 481461,
    SKU: '112031',
    total_qty: 2200,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 493806,
    SKU: '112032',
    total_qty: 6700,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 506151,
    SKU: '112033',
    total_qty: 3900,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 518496,
    SKU: '112034',
    total_qty: 3200,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 530841,
    SKU: '112035',
    total_qty: 5800,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 543186,
    SKU: '112036',
    total_qty: 7200,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 555531,
    SKU: '112037',
    total_qty: 5200,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 567876,
    SKU: '112038',
    total_qty: 8700,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 580221,
    SKU: '112039',
    total_qty: 7500,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 592566,
    SKU: '112040',
    total_qty: 5800,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 604911,
    SKU: '112041',
    total_qty: 2400,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 617256,
    SKU: '112042',
    total_qty: 8500,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 629601,
    SKU: '112043',
    total_qty: 8200,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 641946,
    SKU: '112044',
    total_qty: 6200,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 654291,
    SKU: '112045',
    total_qty: 1500,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 666636,
    SKU: '112046',
    total_qty: 4700,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 678981,
    SKU: '112047',
    total_qty: 5900,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 691326,
    SKU: '112048',
    total_qty: 3100,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 703671,
    SKU: '112049',
    total_qty: 7200,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 716016,
    SKU: '112050',
    total_qty: 6600,
    Priority: 'priority',
    batch: [],
  },
  {
    lot_no: 728361,
    SKU: '112051',
    total_qty: 1800,
    Priority: 'low priority',
    batch: [],
  },
  {
    lot_no: 740706,
    SKU: '112052',
    total_qty: 4100,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 753051,
    SKU: '112053',
    total_qty: 7400,
    Priority: 'priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 765396,
    SKU: '112054',
    total_qty: 3600,
    Priority: 'low priority',
    batch: [
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
      {
        batch_id: '111111-1',
        batch_qty: 850,
        cut_qty: 220,
        cut_date: '12/06/2024',
        sew_qty: 212,
        sew_date: '12/07/2024',
        pck_qty: 210,
        completed: true,
        pck_date: '2024-08-10',
      },
    ],
  },
  {
    lot_no: 777741,
    SKU: '112055',
    total_qty: 4700,
    Priority: 'low priority',
    batch: [],
  },
];
