import React, { useEffect, useState } from 'react';
import { SearchBar, TitleBar, BlpTiles, DropDown } from '../../common';
import { BUTTON, HEADING, PKJ_STATUS, POPUP_TYPE, ROUTES, TITLES } from '../../../constants';
import { Button } from '../../shared';
import { useNavigate } from 'react-router-dom';
import { StatusBox } from '../manufacturing/clothManufacturing/StatusBox';
import { setLoaderVisibility } from '../../../redux';
import { popup, getSortingArray, sortingByName } from '../../../utils';
import { vendorPackage } from '../../../service/package';
import { useDebounce } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { packageDataCompiler } from '../../../utils/dataCompiler/packageDataCompiler';

export const PackageDetail = () => {
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState([]);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState('Box Type');
  const dispatch = useDispatch();

  const onTileClick = (to, data) => {
    navigate(to, { state: data });
  };
  const getPackageList = useDebounce((data = {}) => {
    vendorPackage({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPackageData(packageDataCompiler(response.data));
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: { dataField: ['box_type'] },
                data: response.data,
                compiler: packageDataCompiler,
              }),
            );
          }
        } else {
          setPackageData(response.message);
        }
      })

      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getPackageList();
  }, []);

  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        // ['default_code']: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPackageList(data);
    } else if (!searchTerm && !Array.isArray(packageData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPackageList(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    if (sortingArray1.includes(sort1)) {
      setPackageData(
        sortingByName({
          sortBy: 'box_type',
          data: packageData,
          order: sortingArray1.indexOf(sort1),
        }),
      );
    }
  }, [sort1]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.PACKAGE, route: ROUTES.PACKAGE }]} />
      <div className="bg-white p-3 package-top-info border-def-up border-def-down">
        <StatusBox status={PKJ_STATUS} />
        <div className="d-flex align-items-center justify-content-end mb-3 top-header-set">
          <div className="d-flex align-items-center">
            <h4 className="me-3 mb-0">{HEADING.BOX_COUNT}</h4>
            <div className="number-count d-flex align-items-center justify-content-center">
              {Array.isArray(packageData) && packageData.length}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start gap-3 w-100">
          <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
          <div className="w-50 mb-3 filter-btns-div d-flex align-items-center justify-content-start">
            <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            clickHandler={() => navigate(ROUTES.ADD_MIX_BOX, { state: packageData })}
            className="white-btn d-flex align-items-center justify-content-center px-4 ">
            {BUTTON.ADD_BOX}
          </Button>
        </div>
      </div>
      <div className="bg-white p-3 pt-0 package-titles-info">
        <div className="row custom-row">
          {Array.isArray(packageData) ? (
            packageData.map((tile, index) => (
              <BlpTiles
                key={index}
                tile={tile}
                clickHandler={onTileClick}
                to={ROUTES.PACKAGE_BLP_OVERVIEW}
              />
            ))
          ) : (
            <div>
              <h5 className="px-1 py-5">{packageData}</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
