import React, { useEffect, useState } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import { TITLES,ROUTES, FABRIC_DISPATCH_INNER_COLUMN } from '../../../constants';
import { useLocation } from 'react-router-dom';
import { FabricDispatchModal } from './FabricDispatchModal';
import { EditIcon } from '../../../assets/icons';
import {
  dispatchDetailsBox,
  fabricDispatchProductCompiler,
} from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { vendorReceipt } from '../../../service/receipt';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';

export const FabricDispatchDetail = () => {
  const { state } = useLocation(location?.state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  const getProductsList = useDebounce(() => {
    setReload(false);
    vendorReceipt({
      data: { receipt_ids: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setProductData(response.data[0]?.move_ids);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, [reload]);

  const handleEditIconClick = (row) => {
    setModalData(row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  // Update columns to include handleEditIconClick
  const columns = FABRIC_DISPATCH_INNER_COLUMN.map((col) => {
    if (col.name === 'Action') {
      return {
        ...col,
        customFormatter: (row) => {
          return (
            <>
              {!row.edit && (
                <div className="td-num" onClick={() => handleEditIconClick(row)}>
                  <EditIcon />
                </div>
              )}
            </>
          );
        },
      };
    }
    return col;
  });
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state.name, route: ROUTES.FABRIC_DISPATCH_DETAIL },
        ]}
      />
      <div className="scroll-div">
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <Table
          columns={columns}
          initialData={productData}
          PAGE_SIZE={4}
          colFilter={false}
          compiler={{ use: true, tool: fabricDispatchProductCompiler }}
        />
      </div>
      {isModalOpen && <FabricDispatchModal onClose={handleCloseModal} modalData={modalData} setReload={setReload} />}
    </>
  );
};
