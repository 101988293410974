import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../../redux';
import { useDebounce } from '../../../../hooks';
import { StatusBar } from './StatusBar';
import { TitleBar } from '../../../common';
import { BatchDetails } from './BatchDetails';
import { BatchEditBox } from './BatchEditBox';
import { TITLES, ROUTES, POPUP_TYPE } from '../../../../constants';
import { batchInfo } from '../../../../service/manufacturing';
import { popup, batchDataCompiler } from '../../../../utils';

export const LotInfo = () => {
  const [editBoxState, setEditBoxState] = useState(0);
  const [selectedBatch, setSelectedBatch] = useState('');
  const { state } = useLocation();
  const [reload, setReload] = useState(true);
  const [lot, setLot] = useState({});
  const [field, setField] = useState('');
  const dispatch = useDispatch();

  // API Call
  const getLotDetails = useDebounce(() => {
    batchInfo({
      data: {
        order_line_id: state.id,
      },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        Object.keys(response.data).length
          ? setLot(batchDataCompiler(response.data))
          : popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
    setReload(false);
  }, 1000);
  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getLotDetails();
    }
  }, [reload]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING },
          { text: lot.lot_no, route: ROUTES.NULL },
        ]}
      />
      <div className="w-100 bg-white border-def-up">
        <StatusBar lot={lot} />
        <div className="secondary-scroll-bar">
          <BatchDetails
            setSelectedBatch={setSelectedBatch}
            lot={lot}
            editBoxState={editBoxState}
            setEditBoxState={setEditBoxState}
            setField={setField}
            reload={reload}
            setReload={setReload}
          />
          <BatchEditBox
            selectedBatch={selectedBatch}
            lot={lot}
            setLot={setLot}
            editBoxState={editBoxState}
            setEditBoxState={setEditBoxState}
            field={field}
            setField={setField}
            reload={reload}
            setReload={setReload}
          />
        </div>
      </div>
    </>
  );
};
