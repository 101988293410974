import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES, TITLES } from '../../../constants';
import DetailTable from '../../common/DetailTable';
import { TitleBar } from '../../common';

export const DispatchBlpOverview = () => {
  const { state } = useLocation(location.state);
  const columns = ['SKU', 'Batch No.', 'Quantity'];

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.PACKAGE },
          { text: state.data.BLP, route: null },
        ]}
      />
      <div className="scroll-div">
        <DetailTable
          title={'"' + state.data.BLP + '"' + ' Overview'}
          columns={columns}
          data={state.data.SKU_Details}
        />
      </div>
    </>
  );
};
