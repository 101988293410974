import React from 'react';

export const Dropdown2 = ({ list, show, setShow, selectedColumns, setSelectedColumns }) => {
  {
    setShow;
  }
  const handleCheck = (name) => {
    if (selectedColumns?.includes(name)) {
      setSelectedColumns((pre) => pre.filter((colName) => colName !== name));
    } else {
      setSelectedColumns((pre) => [...pre, name]);
    }
  };

  return (
    <ul className={`dropdown-menu ${show ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
      {list.map((column) =>
        column.name === 'checkbox' ? null : (
          <li key={column.name} className="dropdown-item d-flex align-items-center">
            <div className="form-check">
              <input
                checked={selectedColumns?.includes(column.name)}
                onClick={() => handleCheck(column.name)}
                className="form-check-input"
                type="checkbox"
                value=""
                id={`flexCheck${column.name}`}
                disabled={!column.canBeFiltered}
              />
            </div>
            {column.name}
          </li>
        ),
      )}
    </ul>
  );
};
