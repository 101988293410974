import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const vendorBill = async ({ data = {} }) => {
  const url = API.BILLS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const createBill = async ({ data = {} }) => {
  const url = API.CREATE_BILL;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const updateBill = async ({ data = {} }) => {
  const url = API.UPDATE_BILL;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
