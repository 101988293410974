import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({
  className = '',
  type = 'text',
  value = '',
  name = '',
  onChange,
  onBlur,
  placeholder = '',
  required = false,
  disabled = false,
  readOnly = false,
  ...otherProps
}) => {
  return (
    <input
      className={className}
      type={type}
      value={value}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      required={required}
      disabled={disabled || readOnly}
      readOnly={readOnly}
      {...otherProps}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

