import React from 'react';
const Purchase = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.46045 13.4453H7.64532V15.6302H5.46045V13.4453Z" fill="#707070" />
    <path d="M5.46045 18.6191H7.64532V20.804H5.46045V18.6191Z" fill="#707070" />
    <path d="M5.46045 8.27246H7.64532V10.4573H5.46045V8.27246Z" fill="#707070" />
    <path
      d="M14.966 7.76765H18.1986L13.8735 3.44238V6.67522C13.8735 7.27757 14.3636 7.76765 14.966 7.76765Z"
      fill="#707070"
    />
    <path
      d="M13.6267 21.1634C13.3563 20.8621 13.2259 20.4583 13.2694 20.0559L13.9058 14.1518C13.9835 13.4297 14.5867 12.8851 15.3115 12.8777V12.5145C15.3115 10.9799 16.5599 9.73137 18.0945 9.73137C18.1796 9.73137 18.2634 9.73664 18.3466 9.74415V8.27211H14.9662C14.0858 8.27211 13.3696 7.55585 13.3696 6.67547V3.29492H3.42437C2.91475 3.29492 2.5 3.70951 2.5 4.21929V22.3706C2.5 22.8803 2.91475 23.2949 3.42437 23.2949H17.4222C17.9318 23.2949 18.3466 22.8803 18.3466 22.3706V21.6376H14.6898C14.2849 21.6376 13.8974 21.4647 13.6267 21.1634ZM9.78762 9.11244H13.6217C13.7608 9.11244 13.8738 9.22537 13.8738 9.36455C13.8738 9.50373 13.7608 9.61665 13.6217 9.61665H9.78762C9.64844 9.61665 9.53552 9.50373 9.53552 9.36455C9.53552 9.22537 9.64844 9.11244 9.78762 9.11244ZM8.14945 21.0559C8.14945 21.1951 8.03653 21.308 7.89735 21.308H5.20828C5.0691 21.308 4.95618 21.1951 4.95618 21.0559V18.3668C4.95618 18.2276 5.0691 18.1147 5.20828 18.1147H7.89735C8.03653 18.1147 8.14946 18.2276 8.14946 18.3668L8.14945 21.0559ZM8.14945 15.8826C8.14945 16.0217 8.03653 16.1347 7.89735 16.1347H5.20828C5.0691 16.1347 4.95618 16.0217 4.95618 15.8826V13.1935C4.95618 13.0543 5.0691 12.9414 5.20828 12.9414H7.89735C8.03653 12.9414 8.14946 13.0543 8.14946 13.1935L8.14945 15.8826ZM8.14945 10.7091C8.14945 10.8483 8.03653 10.9612 7.89735 10.9612H5.20828C5.0691 10.9612 4.95618 10.8483 4.95618 10.7091V8.02001C4.95618 7.88083 5.0691 7.76791 5.20828 7.76791H7.89735C8.03653 7.76791 8.14946 7.88083 8.14946 8.02001L8.14945 10.7091ZM11.5249 19.9634H9.78761C9.64843 19.9634 9.53551 19.8505 9.53551 19.7113C9.53551 19.5722 9.64843 19.4592 9.78761 19.4592H11.5249C11.6641 19.4592 11.777 19.5722 11.777 19.7113C11.777 19.8505 11.6641 19.9634 11.5249 19.9634ZM12.5 14.7901H9.78761C9.64843 14.7901 9.53551 14.6772 9.53551 14.538C9.53551 14.3988 9.64843 14.2859 9.78761 14.2859H12.5C12.6392 14.2859 12.7521 14.3988 12.7521 14.538C12.7521 14.6772 12.6392 14.7901 12.5 14.7901Z"
      fill="#707070"
    />
    <path
      d="M22.4182 20.1096L21.7819 14.2058C21.7316 13.7391 21.3424 13.3873 20.8742 13.3814V15.2665C20.8742 15.4057 20.7613 15.5186 20.6221 15.5186C20.4857 15.5186 20.3759 15.4097 20.3716 15.2743C20.3717 15.2715 20.3732 15.2693 20.3732 15.2665V12.5143C20.3732 11.2577 19.3508 10.2354 18.0942 10.2354C16.8377 10.2354 15.8155 11.2577 15.8155 12.5143V13.3804H15.3257C14.8525 13.3804 14.4575 13.7352 14.4068 14.2056L13.7704 20.1096C13.7424 20.3703 13.8266 20.6314 14.0017 20.8264C14.1768 21.0214 14.4274 21.1331 14.6896 21.1331H21.4991C21.7612 21.1331 22.0118 21.0214 22.1869 20.8264C22.3621 20.6314 22.4463 20.3703 22.4182 20.1096ZM16.8206 15.2665C16.8206 15.4057 16.7077 15.5186 16.5685 15.5186C16.4322 15.5186 16.3226 15.4099 16.318 15.2747C16.3182 15.2718 16.3197 15.2694 16.3197 15.2665V12.5143C16.3197 11.5357 17.1157 10.7396 18.0942 10.7396C19.0728 10.7396 19.869 11.5357 19.869 12.5143V13.3804H16.8206V15.2665Z"
      fill="#707070"
    />
  </svg>
);
export default Purchase;
