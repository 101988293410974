import React, { useEffect } from 'react';
import LoginForm from './LoginForm';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE, ROUTES } from '../../../constants';
import { getLocalStorageItem } from '../../../utils';


export const Login = () => {
  const navigate = useNavigate();
  const token = getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN);

  useEffect(() => {
    if (token !== null) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [token]);

  return (
    <main className="main-form h-100">
      <div className="row mx-0">
        <div className="px-0 col-6 login-page-divs d-flex align-items-center justify-content-center left-side">
          <LoginForm />
        </div>
        <div className="px-0 col-6 login-page-divs d-flex align-items-center justify-content-center right-side"></div>
      </div>
    </main>
  );
};
