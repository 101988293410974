import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES, POPUP_TYPE, STATUS, TEXT, FORM_FIELD } from '../../../constants';
import { vendorLogout } from '../../../service';
import { popup } from '../../../utils';


export const Logout = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const response = await vendorLogout();
      if (response.status === STATUS.FAIL) {
        popup(POPUP_TYPE.ERR, response.message || TEXT.NO_LOGOUT);
      } else {
        localStorage.clear();
        navigate(ROUTES.LOGIN);
      }
    } catch (error) {
      popup(POPUP_TYPE.ERR, error.message);
    }
  };

  return (
    <div className="collapse navbar-collapse bg-white" id="ProfileSideBar">
      <p className="user-links mb-0" onClick={handleLogout}>
        {FORM_FIELD.LOG_OUT}
      </p>
    </div>
  );
};
