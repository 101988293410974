import React from 'react';
import { EditIcon } from '../../../assets/icons';

export const ActionBox2 = ({
  lotno,
  unit,
  move_id,
  product,
  demand_quantity,
  setPopUp,
  setPopUpData,
  producing_quantity
}) => {
  return (
    <div className="td-num">
      <EditIcon
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(true);
          setPopUpData({ unit, move_id, product, demand_quantity, lotno,producing_quantity });
        }}
      />
    </div>
  );
};
