import React from 'react';
import { EditIcon } from '../../../../assets/icons';

export const ActionBox1 = ({ data, setPopUp, setPopUpData }) => {
  return (
    <div className="td-num">
      <EditIcon
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(true);
          setPopUpData(data);
        }}
      />
    </div>
  );
};
