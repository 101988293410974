import React from 'react';
const D2 = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M65.3906 7.24512L14.6069 7.24878C14.604 7.24878 14.6016 7.25046 14.5987 7.25046C9.29901 7.25137 5 11.5507 5 16.8502V25.6753C5 27.5753 6.54999 29.1253 8.45001 29.1253H19.4458V70.2554C19.4458 71.2087 19.9878 72.0791 20.8447 72.499C21.6968 72.9202 22.7197 72.8176 23.4741 72.2341L30.3906 66.8936L37.2974 72.2329C38.1958 72.9287 39.4531 72.9299 40.3564 72.2317L47.2461 66.896L54.1333 72.2317C55.0317 72.9299 56.2964 72.9299 57.1948 72.2317L64.082 66.8972L70.957 72.2305C71.4062 72.5771 71.9458 72.7554 72.4902 72.7554C72.8638 72.7554 73.2422 72.6711 73.5889 72.5002C74.4458 72.0803 74.9902 71.21 74.9902 70.2554L75 16.8557C75 11.5566 70.6885 7.24512 65.3906 7.24512ZM19.4501 24.1253H10V16.8502C10 14.3252 12.075 12.2503 14.85 12.2503C17.3096 12.2587 19.4609 14.3026 19.4501 16.8502V24.1253ZM59.85 50.2752H34.6001C33.2249 50.2752 32.1001 49.1502 32.1001 47.7752C32.1001 46.4002 33.2249 45.2752 34.6001 45.2752H59.85C61.225 45.2752 62.35 46.4002 62.35 47.7752C62.35 49.1502 61.225 50.2752 59.85 50.2752ZM59.85 34.7253H34.6001C33.2249 34.7253 32.1001 33.6003 32.1001 32.2253C32.1001 30.8503 33.2249 29.7253 34.6001 29.7253H59.85C61.225 29.7253 62.35 30.8503 62.35 32.2253C62.35 33.6003 61.225 34.7253 59.85 34.7253Z"
      fill="#4B5564"
    />
  </svg>
);
export default D2;
