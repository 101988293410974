import React from 'react';
const Reference = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.53907 10.8125H1.20312C0.814063 10.8125 0.5 11.1275 0.5 11.5156C0.5 11.9038 0.814063 12.2188 1.20312 12.2188H8.53907C8.86485 13.8226 10.2852 15.0312 11.9844 15.0312C13.925 15.0312 15.5 13.456 15.5 11.5156C15.5 9.57523 13.925 8 11.9844 8C10.2852 8 8.86485 9.20867 8.53907 10.8125ZM11.9844 9.40625C13.1492 9.40625 14.0938 10.3515 14.0938 11.5156C14.0938 12.6798 13.1492 13.625 11.9844 13.625C10.8195 13.625 9.875 12.6798 9.875 11.5156C9.875 10.3515 10.8195 9.40625 11.9844 9.40625ZM11.5156 12.2188H12.4531C12.8422 12.2188 13.1562 11.9038 13.1562 11.5156C13.1562 11.1275 12.8422 10.8125 12.4531 10.8125H11.5156C11.1266 10.8125 10.8125 11.1275 10.8125 11.5156C10.8125 11.9038 11.1266 12.2188 11.5156 12.2188ZM7.46093 5.1875H14.7969C15.1859 5.1875 15.5 4.8725 15.5 4.48438C15.5 4.09625 15.1859 3.78125 14.7969 3.78125H7.46093C7.13514 2.17742 5.71484 0.96875 4.01562 0.96875C2.075 0.96875 0.5 2.54398 0.5 4.48438C0.5 6.42477 2.075 8 4.01562 8C5.71484 8 7.13514 6.79133 7.46093 5.1875ZM4.01562 2.375C5.18047 2.375 6.125 3.32023 6.125 4.48438C6.125 5.64852 5.18047 6.59375 4.01562 6.59375C2.85078 6.59375 1.90625 5.64852 1.90625 4.48438C1.90625 3.32023 2.85078 2.375 4.01562 2.375ZM3.54688 5.1875H4.48438C4.87344 5.1875 5.1875 4.8725 5.1875 4.48438C5.1875 4.09625 4.87344 3.78125 4.48438 3.78125H3.54688C3.15781 3.78125 2.84375 4.09625 2.84375 4.48438C2.84375 4.8725 3.15781 5.1875 3.54688 5.1875Z"
      fill="white"
    />
  </svg>
);
export default Reference;
