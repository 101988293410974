import React from 'react';
const D4 = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M75.6845 41.0756V70.1435C75.6845 71.1169 74.8951 71.9062 73.9217 71.9062H5.76274C4.78935 71.9062 4 71.1169 4 70.1435V49.5759C4 48.9075 4.37793 48.2969 4.97585 47.9986L22.0155 39.4984C22.562 39.2258 23.2106 39.2554 23.7296 39.5766C24.2489 39.8978 24.5651 40.465 24.5651 41.0756V46.7266L34.9995 41.5216V24.306C34.9995 23.3327 35.7888 22.5433 36.7622 22.5433H39.9612C40.9346 22.5433 41.724 23.3327 41.724 24.306V46.6674L53.3654 40.8602L54.086 9.62844C54.1082 8.67092 54.8905 7.90625 55.8484 7.90625H63.5329C64.4904 7.90625 65.273 8.67092 65.2953 9.62844L66.0659 43.0249L73.1348 39.4987C73.6813 39.2262 74.3296 39.2558 74.8489 39.577C75.3682 39.8981 75.6845 40.465 75.6845 41.0756ZM16.3666 57.6404H11.5984V64.3409H16.3666V57.6404ZM29.2787 57.6404H24.5105V64.3409H29.2787V57.6404ZM42.1911 57.6404H37.4229V64.3409H42.1911V57.6404ZM55.1035 57.6404H50.3353V64.3409H55.1035V57.6404Z"
      fill="#4B5564"
    />
  </svg>
);
export default D4;
