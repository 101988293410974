import React, { useEffect, useRef, useState } from 'react';
import { getBatchQty } from './ManufacturingContent';
import { apiDateFormat, dateFormatChanger, popup, showDate } from '../../../utils';
import { MfgTable } from './MfgTable';
import {
  BLP_COLUMNS,
  BUTTON,
  HEADING,
  TEXT,
  POPUP_TYPE,
  POPUP_MSG,
  PLACEHOLDER,
  STATUS,
  DROPDOWN,
} from '../../../constants';
import { Button, Input, PopUpBox } from '../../shared';
import { DropDown } from '../../common';
import {
  batchCreation,
  batchQtyUpdate,
  getPrint,
  readyForPack,
} from '../../../service/manufacturing';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
// import { Link } from 'react-router-dom';

const CreateBatch = ({ lot, setEditBoxState, setReload }) => {
  const [batchQty, setBatchQty] = useState('');
  const batchQuantity = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    batchQuantity?.current?.focus();
  }, []);

  const handleBatchCreator = () => {
    if (batchQty < 1) {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    } else if (batchQty > lot.total_qty - lot.ready_qty) {
      popup(POPUP_TYPE.ERR, `${POPUP_MSG.MAX_QTY} : ${lot.total_qty - lot.ready_qty}`);
    } else {
      dispatch(setLoaderVisibility(true));
      batchCreation({
        data: {
          move_id: [lot.move_id],
          data: {
            order_qty: +batchQuantity.current.value,
            batch_no: `${lot.lot_no}-${+lot.batch.length + 1}`,
          },
        },
      })
        .then((response) =>
          response.data.length
            ? popup(POPUP_TYPE.SUCCESS, response.message)
            : popup(POPUP_TYPE.ERR, response.message),
        )
        .catch((error) => {
          popup(error.message);
        });
      setReload(true);
      setEditBoxState(0);
    }
  };

  return (
    <div className="edit-container p-4">
      <h5 className="text-center mb-4 mt-0">{HEADING.BATCH_CREATION}</h5>
      <div className="row mx-0 edit-set-box">
        <div className="col-4">{TEXT.BATCH_NO}</div>
        <div className="col-8">{`${lot.lot_no} - ${lot.batch.length + 1}`} </div>
        <div className="col-4">{TEXT.ORDER_QTY}</div>
        <input
          ref={batchQuantity}
          type="number"
          className="col-8"
          placeholder={PLACEHOLDER.ENTER_BATCH_QTY}
          value={batchQty}
          max={lot.total_qty - getBatchQty(lot)}
          min={1}
          onChange={(e) => setBatchQty(+e.target.value)}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center gap-4 w-100 mt-4">
        <Button
          clickHandler={handleBatchCreator}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.CREATE_BATCH}
        </Button>

        <Button
          clickHandler={() => {
            setEditBoxState(0);
          }}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.CLOSE}
        </Button>
      </div>
    </div>
  );
};
const EditBatch = ({ field, setField, selectedBatch, setEditBoxState, setReload, setBlpData }) => {
  const cutQuantity = useRef();
  const sewQuantity = useRef();
  const pckQuantity = useRef();
  // const dyeQuantity = useRef();
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [cutQty, setCutQty] = useState(selectedBatch.cut_qty);
  const [cutDate, setCutDate] = useState(selectedBatch.cut_date);
  const [sewQty, setSewQty] = useState(selectedBatch.sew_qty);
  const [sewDate, setSewDate] = useState(selectedBatch.sew_date);
  // const [dyeQty, setDyeQty] = useState(selectedBatch.dye_qty);
  // const [dyeDate, setDyeDate] = useState(selectedBatch.dye_date);
  const [pckQty, setPckQty] = useState(selectedBatch.pack_qty);
  const [pckDate, setPckDate] = useState(selectedBatch.pack_date);

  const dispatch = useDispatch();

  useEffect(() => {
    if (field === STATUS.CUT) {
      cutQuantity.current.focus();
    } else if (field === STATUS.SEW) {
      sewQuantity.current.focus();
      // } else if (field === STATUS.DYE) {
      //   dyeQuantity.current.focus();
    } else if (field === STATUS.PACK) {
      pckQuantity.current.focus();
    }
  }, []);
  const handleCutChange = (e) => {
    setCutQty(e.target.value);
    setField(STATUS.CUT);
  };

  const handleSewChange = (e) => {
    setSewQty(+e.target.value);
    setField(STATUS.SEW);
  };
  // const handleDyeChange = (e) => {
  //   setDyeQty(+e.target.value);
  //   setField(STATUS.DYE);
  // };
  const handlePckChange = (e) => {
    setPckQty(+e.target.value);
    setField(STATUS.PACK);
  };
  const handleSubmit = () => {
    if (field === STATUS.READY) {
      readyForPack({
        data: {
          batch_id: [selectedBatch.id],
        },
      })
        .then((response) => {
          if (response.data.length > 0) {
            popup(POPUP_TYPE.SUCCESS, response.message);
            setEditBoxState(3);
            setBlpData(response.data);
          } else {
            popup(POPUP_TYPE.ERR, response.message);
            setEditBoxState(0);
          }
        })
        .catch((err) => popup(POPUP_TYPE.ERR, err.message));
      setReload(true);
    } else {
      setAllowUpdate(true);
    }
  };

  const wrongQty = () => {
    popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY), setAllowUpdate(false);
  };
  const dataCheck = () => {
    let batchUpdate = {};
    if (field === STATUS.CUT) {
      cutQty > selectedBatch.order_qty || cutQty < 1
        ? wrongQty()
        : (batchUpdate = {
            cut_qty: cutQty,
            cut_date: apiDateFormat(cutDate),
          });
    } else if (field === STATUS.SEW) {
      sewQty > cutQty || sewQty < 1
        ? wrongQty()
        : (batchUpdate = {
            sew_qty: sewQty,
            sew_date: apiDateFormat(sewDate),
          });
      // } else if (field === STATUS.DYE) {
      //   dyeQty > sewQty || dyeQty < 1
      //     ? wrongQty()
      //     : (batchUpdate = {
      //         dye_qty: dyeQty,
      //         dye_date: apiDateFormat(dyeDate),
      //       });
    } else if (field === STATUS.PACK) {
      pckQty > sewQty || pckQty < 1
        ? wrongQty()
        : (batchUpdate = {
            pack_qty: pckQty,
            pack_date: apiDateFormat(pckDate),
          });
    }
    return batchUpdate;
  };
  useEffect(() => {
    if (allowUpdate) {
      const batchUpdate = dataCheck();
      if (Object.keys(batchUpdate)?.length > 0) {
        dispatch(setLoaderVisibility(true));
        batchQtyUpdate({ data: { batch_id: [selectedBatch.id], data: batchUpdate } })
          .then((response) => popup(POPUP_TYPE.SUCCESS, response.message))
          .catch((err) => popup(POPUP_TYPE.SUCCESS, err.message));
        setReload(true);
        setEditBoxState(0);
      }
    }
  }, [allowUpdate]);
  return (
    <div className="edit-container p-4">
      <h5 className="text-center mb-4 mt-0">{HEADING.EDIT_BATCH}</h5>
      <div className="row mx-0 edit-set-box">
        <div className="col-4">{TEXT.BATCH_NO}</div>
        <div className="col-8">{selectedBatch.name}</div>
        <div className="col-4">{TEXT.ORDER_QTY}</div>
        <div className="col-8">{selectedBatch.order_qty}</div>
        <div className="col-4">{TEXT.CUT_QTY}</div>
        {/* Cutting part \/ */}
        {field === STATUS.CUT ? (
          <input
            ref={cutQuantity}
            type="number"
            className="col-8"
            placeholder={selectedBatch.batch_qty}
            min={1}
            // value={cutQty}
            onChange={handleCutChange}
          />
        ) : (
          <div className="col-8">{selectedBatch.cut_qty}</div>
        )}
        <div className="col-4">{TEXT.CUT_DATE}</div>
        {field === STATUS.CUT ? (
          <Input
            type="date"
            className="col-8"
            placeholder={PLACEHOLDER.ENTER_DATE}
            value={showDate(cutDate)}
            disabled={!selectedBatch.order_qty}
            onChange={(e) => setCutDate(e.target.value)}
          />
        ) : (
          <div className="col-8">{dateFormatChanger(cutDate)}</div>
        )}
        {/* Sew part \/ */}
        <div className="col-4">{TEXT.SEW_QTY}</div>
        {field === STATUS.SEW ? (
          <input
            ref={sewQuantity}
            type="number"
            className="col-8"
            placeholder={selectedBatch.cut_qty}
            disabled={selectedBatch.sew_qty}
            // value={sewQty}
            min={1}
            onChange={handleSewChange}
          />
        ) : (
          <div className="col-8">{selectedBatch.sew_qty}</div>
        )}
        <div className="col-4">{TEXT.SEW_DATE}</div>
        {field === STATUS.SEW ? (
          <Input
            type="date"
            className="col-8"
            placeholder={PLACEHOLDER.ENTER_DATE}
            value={showDate(sewDate)}
            onChange={(e) => setSewDate(e.target.value)}
          />
        ) : (
          <div className="col-8">{dateFormatChanger(sewDate)}</div>
        )}
        {/* dye part */}
        {/* <div className="col-4">{TEXT.DYE_QTY}</div>
        {field === STATUS.DYE ? (
          <input
            ref={dyeQuantity}
            type="number"
            className="col-8"
            placeholder={selectedBatch.dye_qty}
            onChange={handleDyeChange}
          />
        ) : (
          <div className="col-8">{dyeQty}</div>
        )}
        <div className="col-4">{TEXT.DYE_DATE}</div>
        {field === STATUS.DYE ? (
          <Input
            type="date"
            className="col-8"
            placeholder={PLACEHOLDER.ENTER_DATE}
            value={showDate(dyeDate)}
            onChange={(e) => setDyeDate(() => e.target.value)}
          />
        ) : (
          <div className="col-8">{dateFormatChanger(dyeDate)}</div>
        )} */}
        {/* package part \/ */}
        <div className="col-4">{TEXT.PACKAGE_QTY}</div>
        {field === STATUS.PACK ? (
          <input
            ref={pckQuantity}
            type="number"
            className="col-8"
            placeholder={selectedBatch.pack_qty}
            onChange={handlePckChange}
          />
        ) : (
          <div className="col-8">{pckQty}</div>
        )}
        <div className="col-4">{TEXT.PACKAGE_DATE}</div>
        {field === STATUS.PACK ? (
          <Input
            type="date"
            className="col-8"
            placeholder={PLACEHOLDER.ENTER_DATE}
            value={showDate(pckDate)}
            onChange={(e) => setPckDate(() => dateFormatChanger(e.target.value))}
          />
        ) : (
          <div className="col-8">{dateFormatChanger(pckDate)}</div>
        )}
      </div>
      {/* Buttons */}
      <div className="d-flex align-items-center justify-content-center gap-4 w-100 mt-4">
        <Button
          clickHandler={handleSubmit}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {field === STATUS.READY ? BUTTON.READY_FOR_PACK : BUTTON.SAVE}
        </Button>

        <Button
          clickHandler={() => setEditBoxState(0)}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.CLOSE}
        </Button>
      </div>
    </div>
  );
};

export const BLPList = ({ data, setEditBoxState, setPrintUrl }) => {
  const dispatch = useDispatch();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [boxInfo, setBoxInfo] = useState({});
  const handlePrint = () => {
    setOpenPopUp(true);
  };
  const getPrintUrl = () => {
    getPrint({
      data: boxInfo,
    })
      .then((response) => {
        if (response.data.length > 0) {
          popup(POPUP_TYPE.SUCCESS, response.message);
          dispatch(setLoaderVisibility(false));
          setPrintUrl(response.data[0]);
          setEditBoxState(4);
        } else {
          popup(POPUP_TYPE.ERR, response.message);
          dispatch(setLoaderVisibility(false));
        }
      })
      .catch((err) => popup(POPUP_TYPE.SUCCESS, err.message));
  };
  useEffect(() => {
    if (Object.keys(boxInfo).length) {
      dispatch(setLoaderVisibility(true));
      getPrintUrl();
    }
  }, [boxInfo]);

  return (
    <div className="edit-container p-4">
      <h5 className="text-center mb-4 mt-0">{BUTTON.GENERATE_BLP}</h5>
      <div className="w-100 table-3">
        <MfgTable column={BLP_COLUMNS} data={data} clickHandler={() => {}} />
      </div>
      {openPopUp && (
        <PopUpBox openConfirmationBox={openPopUp}>
          <BoxInfo
            selectedPackage={data.map((pck) => pck.id)}
            setOpenPopUp={setOpenPopUp}
            setBoxInfo={setBoxInfo}
          />
        </PopUpBox>
      )}
      <div className="d-flex align-items-center justify-content-center w-100 mt-4">
        <Button
          clickHandler={handlePrint}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.PRINT_LABEL}
        </Button>
      </div>
    </div>
  );
};
const BoxInfo = ({ selectedPackage, setOpenPopUp, setBoxInfo }) => {
  const [option, setOption] = useState(DROPDOWN.BOX_OPTION[0]);
  const boxInfo = {
    sizes: [option], // 12_4, 4_6
    package_types: ['single'], // single, mix
    package_ids: [...selectedPackage],
  };
  const handleBoxInfo = () => {
    setBoxInfo(boxInfo);
    setOpenPopUp(false);
  };
  return (
    <div className="p-3 ">
      <div className="d-flex flex-column gap-3  ms-4">
        <div className="d-flex justify-content-between ms-5 me-5">
          <label>{TEXT.BOX_SIZE}</label>
          <DropDown dropdownMenu={DROPDOWN.BOX_OPTION} value={option} setValue={setOption} />
        </div>
        <div className="d-flex justify-content-between ms-5 me-5">
          <label>{TEXT.BOX_TYPE}</label>
          <input
            className="w-50 ps-2"
            type="text"
            disabled={true}
            value={boxInfo.package_types[0]}
          />
        </div>
        <div className="d-flex justify-content-center gap-3">
          <Button
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3"
            clickHandler={handleBoxInfo}
            text={BUTTON.SAVE}></Button>
          <Button
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3"
            clickHandler={() => setOpenPopUp(false)}
            text={BUTTON.CANCEL}></Button>
        </div>
      </div>
    </div>
  );
};
const PrintScreen = ({ url, setEditBoxState }) => {
  const url1 = url.split('?')[0];
  const openInNewTab = (url1) => {
    window.open(url1, '_blank', 'noreferrer');
    setEditBoxState(0);
  };
  return (
    <div className="d-flex justify-content-center">
      <Button
        className="border-1 bg-info d-flex align-items-center justify-content-center py-2 px-3"
        role="link"
        clickHandler={() => openInNewTab(url1)}>
        {BUTTON.PRINT_LABEL}
      </Button>
    </div>
  );
};
export const BatchEditBox = ({
  field,
  setField,
  selectedBatch,
  lot,
  editBoxState,
  setEditBoxState,
  setLot,
  setReload,
}) => {
  const [blpData, setBlpData] = useState([]);
  const [printUrl, setPrintUrl] = useState('');
  useEffect(() => {
    if (editBoxState === 4 && printUrl) {
      parent.postMessage({ action: 'receipt-loaded' });
    }
  }, [printUrl]);
  return (
    <div className="w-100 py-4 editing-container">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-lg-9 col-11">
          {editBoxState === 1 && (
            <CreateBatch
              lot={lot}
              setLot={setLot}
              editBoxState={editBoxState}
              setEditBoxState={setEditBoxState}
              selectedBatch={selectedBatch}
              setReload={setReload}
            />
          )}
          {editBoxState === 2 && (
            <EditBatch
              setLot={setLot}
              setEditBoxState={setEditBoxState}
              selectedBatch={selectedBatch}
              field={field}
              setField={setField}
              setReload={setReload}
              setBlpData={setBlpData}
            />
          )}
          {editBoxState === 3 && (
            <BLPList
              data={blpData}
              selectedBatch={selectedBatch}
              setEditBoxState={setEditBoxState}
              setPrintUrl={setPrintUrl}
            />
          )}
          {editBoxState === 4 && <PrintScreen url={printUrl} setEditBoxState={setEditBoxState} />}
        </div>
      </div>
    </div>
  );
};
