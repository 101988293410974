import React from 'react';
const Sort = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_359_7119)">
      <path
        d="M5.99998 8.18179H3.81816V0.545453C3.81816 0.244217 3.57391 0 3.27271 0C2.97144 0 2.72726 0.244217 2.72726 0.545453V8.18179H0.545447C0.324866 8.18179 0.125921 8.3147 0.041521 8.51852C-0.0428788 8.72234 0.0037756 8.95695 0.159775 9.11295L2.887 11.8402C2.98929 11.9425 3.12802 12 3.27271 12C3.41737 12 3.55609 11.9425 3.65835 11.8402L6.38561 9.11292C6.54165 8.95692 6.5883 8.7223 6.50387 8.51848C6.41947 8.3147 6.22056 8.18179 5.99998 8.18179Z"
        fill="white"
      />
      <path
        d="M11.8402 2.88703L9.11298 0.159763C8.9 -0.0532544 8.55462 -0.0532544 8.34161 0.159763L5.61434 2.88703C5.45838 3.04303 5.41172 3.27764 5.49609 3.48146C5.58052 3.68528 5.77943 3.81819 6.00005 3.81819H8.18186V11.4546C8.18186 11.7558 8.42604 12 8.72731 12C9.02851 12 9.27277 11.7558 9.27277 11.4546V3.81815H11.4546C11.6752 3.81815 11.8741 3.68524 11.9585 3.48143C12.0429 3.27761 11.9963 3.04303 11.8402 2.88703Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_359_7119">
        <rect width={12} height={12} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Sort;
