import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from '../../constants';
import {
  DashboardContent,
  DispatchContent,
  ManufacturingContent,
  PoContent,
  ReportsContent,
  ReceiptContent,
  BillsContent,
  DispatchDetail,
  LotInfo,
  ReceiptDetails,
  PoDetails,
  PackageDetail,
  BillsDetails,
  MixBox,
  CreateDispatch,
  FabricDispatchDetail,
  PackageBlpOverview,
  YarnDispatchDetail,
  DispatchBlpOverview,
} from '../pages';

const RenderDashboard = (selectedTab) => {
  const url = selectedTab.split('/');
  switch (selectedTab) {
    case ROUTES.DASHBOARD:
      return <DashboardContent />;
    case ROUTES.PURCHASE_ORDER:
      return <PoContent />;
    case `${ROUTES.PURCHASE_ORDER}/${url[2]}`:
      return <PoDetails />;
    case ROUTES.BILLS:
      return <BillsContent />;
    case `${ROUTES.BILLS}/${url[2]}`:
      return <BillsDetails />;
    case ROUTES.RECEIPT:
      return <ReceiptContent />;
    case `${ROUTES.RECEIPT}/${url[2]}`:
      return <ReceiptDetails />;
    case ROUTES.MANUFACTURING:
      return <ManufacturingContent />;
    case `${ROUTES.MANUFACTURING}/${url[2]}`:
      return <LotInfo />;
    case ROUTES.PACKAGE:
      return <PackageDetail />;
    case ROUTES.PACKAGE_BLP_OVERVIEW:
      return <PackageBlpOverview />;
    case ROUTES.DISPATCH_BLP_OVERVIEW:
      return <DispatchBlpOverview />;
    case ROUTES.ADD_MIX_BOX:
      return <MixBox />;
    case ROUTES.DISPATCH:
      return <DispatchContent />;
    case ROUTES.CREATE_DISPATCH:
      return <CreateDispatch />;
    case `${ROUTES.DISPATCH_DETAILS}/${url[2]}`:
      return <DispatchDetail />;
    case `${ROUTES.FABRIC_DISPATCH_DETAIL}/${url[2]}`:
      return <FabricDispatchDetail />;
    case `${ROUTES.YARN_DISPATCH_DETAIL}/${url[2]}`:
      return <YarnDispatchDetail />;
    case ROUTES.REPORTS:
      return <ReportsContent />;
    default:
      return <DashboardContent />;
  }
};

export const ContentBox = ({ currentRoute }) => {
  return <div className="main-content">{RenderDashboard(currentRoute)}</div>;
};

ContentBox.propTypes = {
  currentRoute: PropTypes.string.isRequired,
};
