import  React from 'react';
const DeleteRedIcon = (props) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_930_63823)">
      <path
        d="M18.625 3H14.875V2.375C14.875 1.34106 14.0339 0.5 13 0.5H8C6.96606 0.5 6.125 1.34106 6.125 2.375V3H2.375C2.02984 3 1.75 3.27954 1.75 3.625C1.75 3.97046 2.02984 4.25 2.375 4.25H6.75H14.25H18.625C18.9705 4.25 19.25 3.97046 19.25 3.625C19.25 3.27954 18.9705 3 18.625 3Z"
        fill="#FF7272"
      />
      <path
        d="M18 5.5C18 5.5 3.01728 5.5 3.00002 5.5C2.6609 5.5 2.35952 5.79362 2.37562 6.15307L2.94141 18.7184C2.99116 19.7175 3.81392 20.5 4.81397 20.5H16.186C17.1864 20.5 18.0092 19.7175 18.0586 18.7153C18.0586 18.7153 18.6229 6.18652 18.6244 6.15314C18.6403 5.80008 18.3527 5.5 18 5.5ZM7.23585 16.7494C7.2267 16.75 7.21754 16.75 7.20839 16.75C6.87574 16.75 6.59895 16.4876 6.5846 16.1519L6.2898 9.27686C6.27485 8.93201 6.54249 8.64026 6.88734 8.62561C7.23188 8.61036 7.52393 8.8783 7.53858 9.22314L7.83338 16.0981C7.84834 16.443 7.5807 16.7347 7.23585 16.7494ZM11.125 16.125C11.125 16.4705 10.8452 16.75 10.5 16.75C10.1549 16.75 9.87501 16.4705 9.87501 16.125V9.25C9.87501 8.90454 10.1549 8.625 10.5 8.625C10.8452 8.625 11.125 8.90454 11.125 9.25V16.125ZM14.4148 16.1519C14.4002 16.4876 14.1237 16.75 13.791 16.75C13.7819 16.75 13.7727 16.75 13.7636 16.7494C13.4187 16.7347 13.1514 16.443 13.166 16.0981L13.4614 9.22314C13.4767 8.87829 13.7727 8.61157 14.1127 8.62561C14.4575 8.64026 14.7249 8.93201 14.7102 9.27686L14.4148 16.1519Z"
        fill="#FF7272"
      />
    </g>
    <defs>
      <clipPath id="clip0_930_63823">
        <rect width={20} height={20} fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default DeleteRedIcon;
