import React from 'react';

export const Total = ({ qty, total, uom, currency_code }) => {
  return (
    <div className="main-total-container bg-white d-flex flex-column align-items-end justify-content-end mb-3">
      <h6 className="txt d-flex align-items-end justify-content-end">
        Total Unit Quantity:
        <span className="total-span">{`${qty?.toFixed(2)} ${uom}`}</span>
      </h6>
      <h6 className="txt d-flex align-items-end justify-content-between ">
        Total:
        <span className="total-span">{` ${total?.toFixed(2)} ${currency_code || ''}`}</span>
      </h6>
    </div>
  );
};
