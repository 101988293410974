import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import sliderIcon from '../../assets/img/slider-btn.webp';
import {
  DashboardIcon,
  Packages,
  Purchase,
  Receipt,
  Bills,
  Manufacturing,
  Dispatch,
  Reports,
} from '../../assets/icons';
import { APPS, LOCAL_STORAGE, ROUTES } from '../../constants';
import { Button } from '../shared';
import { getLocalStorageItem } from '../../utils';

export const Sidebar = ({ currentDashboardRoute, setCurrentDashboardRoute }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const sidebarOptions = [
    {
      name: 'Dashboard',
      tag: APPS.DASHBOARD,
      icon: DashboardIcon,
      route: ROUTES.DASHBOARD,
    },
    {
      name: 'Order',
      tag: APPS.PURCHASE,
      icon: Purchase,
      route: ROUTES.PURCHASE_ORDER,
    },
    {
      name: 'Bills',
      tag: APPS.BILL,
      icon: Bills,
      route: ROUTES.BILLS,
    },
    {
      name: 'Receipt',
      tag: APPS.RECEIPT,
      icon: Receipt,
      route: ROUTES.RECEIPT,
    },
    {
      name: 'Manufacturing',
      tag: APPS.MFG,
      icon: Manufacturing,
      route: ROUTES.MANUFACTURING,
    },
    {
      name: 'Package',
      tag: APPS.PACK,
      icon: Packages,
      route: ROUTES.PACKAGE,
    },
    {
      name: 'Dispatch',
      tag: APPS.DISPATCH,
      icon: Dispatch,
      route: ROUTES.DISPATCH,
    },
    {
      name: 'Report',
      tag: APPS.DASHBOARD,
      icon: Reports,
      route: ROUTES.REPORTS,
    },
  ];

  const allowedApps = [APPS.DASHBOARD, ...getLocalStorageItem(LOCAL_STORAGE.ALLOWED_APP)];
  const filteredSidebarOptions = sidebarOptions.filter((option) =>
    allowedApps.includes(option.tag),
  );
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <Button
        className={`slider-btn position-absolute d-flex align-items-center justify-content-center d-lg-none d-block bg-white p-1 ${
          showSidebar ? 'open' : ''
        }`}
        clickHandler={toggleSidebar}
        type="button"
        id="toggleButton">
        <img src={sliderIcon} alt="arrow-icon" width="14" height="14" />
      </Button>
      <div
        className={`sidebar-main-view flex-column d-flex align-items-start justify-content-start bg-white ${
          showSidebar ? 'show' : ''
        }`}
        id="MenuSidebarMobileView2">
        <div className="w-100">
          <ul className="ps-0 w-100">
            {filteredSidebarOptions.map((option, index) => (
              <li
                key={index}
                className={`sidebar-list d-flex align-items-center position-relative ${
                  option.route === currentDashboardRoute ? 'active' : ''
                }`}
                onClick={() => {
                  setCurrentDashboardRoute(option.route);
                  navigate(option.route);
                }}>
                <option.icon />
                <span>{option.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  currentDashboardRoute: PropTypes.string.isRequired,
  setCurrentDashboardRoute: PropTypes.func.isRequired,
};
