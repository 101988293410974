import React, { useEffect, useState, useRef } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import {
  TITLES,
  ROUTES,
  YARN_DISPATCH_INNER_COLUMN,
  POPUP_TYPE,
  POPUP_MSG,
  BUTTON,
  LOCAL_STORAGE,
} from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopUpBox, Button } from '../../shared';
import {
  dispatchDetailsBox,
  yarnDispatchProductCompiler,
  fabricDispatchProductCompiler,
} from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import {
  updateReceiptQty,
  vendorReceipt,
  markDispatched,
  packageCreationDyeing,
} from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, getLocalStorageItem } from '../../../utils';
import PrintLabelModal from './PrintLabelModal';
import { printLabelPackage } from '../../../service/package';

export const YarnDispatchDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation(location?.state);
  const [productData, setProductData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [popUpData, setPopUpData] = useState('');

  const getProductsList = useDebounce(() => {
    vendorReceipt({
      data: { receipt_ids: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (getLocalStorageItem('tags')[0] === 'dyeing')
          setProductData(fabricDispatchProductCompiler(response.data[0]));
        else setProductData(response.data[0]?.move_ids);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, []);

  const handlePrintLabelClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const canShowDispatch = () => {
    if (productData?.length) {
      return productData?.every((move) => (move?.move_line_ids[0]?.lot_name !== false)
       || (move?.move_line_ids[0]?.product_id?.tracking!=='lot' && move?.move_line_ids[0]?.lot_name === false)
      );
    }
  };

  const handleSubmitModal = (payload) => {
    printLabelPackage({
      data: payload,
    })
      .then((response) => {
        if (response.data.length > 0) {
          const url = response.data[0].split('?')[0];
          window.open(url, '_blank', 'noreferrer');
        }
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const createPackage = () => {
    dispatch(setLoaderVisibility(true));
    packageCreationDyeing({ data: { picking_id: [state?.id] } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const validateDispatch = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };
  const submitDispatch = () => {
    dispatch(setLoaderVisibility(true));
    markDispatched({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        if(getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE)!=='finished_goods')
          navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  };
  const column =
    state?.dispatch_status !== 'draft' || state?.state === 'done'
      ? YARN_DISPATCH_INNER_COLUMN.filter((col) => col.name !== 'Action')
      : YARN_DISPATCH_INNER_COLUMN;
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: null },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-end gap-2 mb-3">
          {state.dispatch_status === 'dispatched' &&
            getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'finished_goods' &&
            (productData?.every((line) => line.packages_ids?.length === 0) ? (
              <Button
                clickHandler={createPackage}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.READY_FOR_PACK}
              </Button>
            ) : (
              <Button
                clickHandler={handlePrintLabelClick}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.PRINT_LABEL}
              </Button>
            ))}
          {state?.dispatch_status === 'draft' && (
            <Button
              clickHandler={submitDispatch}
              disabled={getLocalStorageItem('tags')[0] !== 'dyeing' && !canShowDispatch()}
              className={`blue-btn ${getLocalStorageItem('tags')[0] !== 'dyeing' && !canShowDispatch() ? 'disabled' : ''} border-0 d-flex align-items-center justify-content-center py-2 px-3`}>
              {BUTTON.DISPATCH}
            </Button>
          )}
        </div>
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <Table
          columns={column}
          initialData={productData}
          PAGE_SIZE={4}
          colFilter={false}
          compiler={
            getLocalStorageItem('tags')[0] !== 'dyeing'
              ? { use: true, tool: yarnDispatchProductCompiler }
              : { use: false }
          }
          popUp={validate}
          setPopUp={setValidate}
          setPopUpData={setPopUpData}
        />
        {validate && (
          <PopUpBox openPopUpBox={validate}>
            <YarnDispatchValidate
              apiCall={validateDispatch}
              setOpenPopUpBox={setValidate}
              data={popUpData}
            />
          </PopUpBox>
        )}
        {isModalOpen && (
          <PrintLabelModal
            onClose={handleCloseModal}
            onSubmit={handleSubmitModal}
            packageData={productData}
          />
        )}
      </div>
    </>
  );
};
const YarnDispatchValidate = ({ apiCall, setOpenPopUpBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const [lotNo, setLotNo] = useState('');
  const qtyRef = useRef();
  const lotRef = useRef();
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenPopUpBox(false);
      const apiData =
        data.lotno === 'N/A' && getLocalStorageItem('tags')[0] !== 'yarn'
          ? { move_id: data.move_id, done_qty: +doneQty }
          : {
              move_id: data.move_id,
              done_qty: +doneQty,
              lot_name: lotNo,
            };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    if (lotRef.current) {
      lotRef.current.focus();
    } else {
      qtyRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h5 className=" text-center text-special mb-3 "> {data.product} </h5>
        <div className="w-100 modal-body py-0 d-flex flex-column gap-3 justify-content-center align-items-center">
          {getLocalStorageItem('tags')[0] === 'yarn' && data.lotno !== 'N/A' && (
            <input
              type="text"
              className="ps-2 curve-border"
              placeholder={data.lotno || 'Enter lot Number'}
              ref={lotRef}
              onChange={(e) => {
                setLotNo(e.target.value);
              }}
            />
          )}
          <input
            type="number"
            className="ps-2 curve-border placeholder-text"
            placeholder={data.producing_quantity || 'Enter dispatched quantity'}
            ref={qtyRef}
            onChange={(e) => {
              setDoneQty(e.target.value);
            }}
          />
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
