import React from 'react';
import { TITLES, ROUTES } from '../../../constants';
import { TitleBar } from '../../common';
import MixBoxInputTable from './MixBoxInputTable';

export const MixBox = () => {
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PACKAGE, route: ROUTES.PACKAGE },
          { text: TITLES.ADD_BOX, route: ROUTES.ADD_MIX_BOX },
        ]}
      />
      <div className="scroll-div">
        <MixBoxInputTable />
      </div>
    </>
  );
};
