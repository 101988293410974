import React from 'react';
const Separator = (props) => (
  <svg
    width={2}
    height={15}
    viewBox="0 0 2 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.38636 0.460227V14.8125H0.534091V0.460227H1.38636Z" fill="#4B5564" />
  </svg>
);
export default Separator;
