import React from 'react';
import { PaginationArrow } from '../../assets/icons';
import {Button} from '../shared';
import PropTypes from 'prop-types';

export const Pagination = ({ currentPage, totalPages, onPrevious, onNext }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <Button className="w-auto px-5 blue-btn" onClick={onPrevious} disabled={currentPage === 1}>
          Back
        </Button>
      </div>
      <div className="pagination-right d-flex align-items-center justify-content-start">
        <Button className="p-0 border-0" onClick={onPrevious} disabled={currentPage === 1}>
          <PaginationArrow className="img-rotate" />
        </Button>
        Page {currentPage} of {totalPages}
        <Button className="p-0 border-0" onClick={onNext} disabled={currentPage === totalPages}>
          <PaginationArrow />
        </Button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};


