import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DetailBox, Table, TitleBar, Total } from '../../common';
import {
  PO_DETAILS_COLUMN,
  TITLES,
  ROUTES,
  BUTTON,
  POPUP_TYPE,
  DATA_FIELD,
} from '../../../constants';
import { poDetailsCompiler, poDetailsBox, apiDateFormat2, popup } from '../../../utils';
import { Button } from '../../shared';
import { createBill } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { reducedCalc } from '../../../utils';

export const PoDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location?.state);
  const data = poDetailsCompiler(state?.order_line);
  const [value, setValue] = useState('');
  const [editBill, setEditBill] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const qty = reducedCalc(data, DATA_FIELD.RECEIVED);
  const totalInvoiced = reducedCalc(data, DATA_FIELD.BILLED);

  const handleBillCreation = () => {
    dispatch(setLoaderVisibility(true));
    createBill({
      data: { purchase_id: state.id, bill_date: apiDateFormat2(value) },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.BILLS);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };
  const AddDateField = (bool) => {
    state.createBill = bool;
    setEditBill(bool);
  };

  useEffect(() => {
    if (value) {
      setShowSave(true);
    }
  }, [value]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PURCHASE_ORDER, route: ROUTES.PURCHASE_ORDER },
          { text: editBill ? TITLES.CREATE_BILL : state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-end gap-2 mb-3">
          {qty > totalInvoiced && !editBill && (
            <Button
              clickHandler={() => AddDateField(true)}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {BUTTON.ADD_BILL}
            </Button>
          )}
          {editBill && (
            <Button
              clickHandler={() => AddDateField(false)}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {BUTTON.CANCEL}
            </Button>
          )}
        </div>
        <DetailBox
          colCount={2}
          colData={state}
          dataCompiler={poDetailsBox}
          value={value}
          setValue={setValue}
          clickHandler={handleBillCreation}
          showSave={showSave}
        />
        <Table columns={PO_DETAILS_COLUMN} initialData={data} PAGE_SIZE={4} colFilter={false} />
        <Total
          qty={qty}
          total={state?.amount_total}
          uom={data[0]?.uom}
          currency_code={state?.currency_id.name}
        />
      </div>
    </>
  );
};
