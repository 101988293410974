import React from 'react';
import { Packages, EditIcon } from '../assets/icons';
import { ActionBox } from '../components/pages/receipt/ActionBox';
import { ActionBox2 } from '../components/pages/dispatch/ActionBox2';
export const PO_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['name'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },

  {
    name: 'Scheduled Date/Time',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['schedule_date'],
    canBeFiltered: false,
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['origin'],
    canBeFiltered: false,
  },
  {
    name: 'Bill Status',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['invoice_status'],
  },

  {
    name: 'Qty Status',
    classNameTH: '',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['qty_status'],
    canBeFiltered: true,
    customFormatter: ({ qty_status }) => {
      return (
        <div
          className={`col-${qty_status === 'pending' ? 'waiting' : qty_status === 'full' ? 'received' : 'dispatched'} pills col-received d-flex ps-2 justify-content-center status-pills`}>
          <p>{qty_status}</p>
        </div>
      );
    },
  },
];

export const PO_DETAILS_COLUMN = [
  {
    name: '',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'Quantity',
    classNameTH: 'quantity-td',
    classNameTD: 'quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['qty'],
  },
  {
    name: 'Delivered',
    classNameTH: 'received-td',
    classNameTD: 'received-td text-center',
    dataField: ['received'],
  },
  {
    name: 'Undelivered',
    classNameTH: 'undelivered-td',
    classNameTD: 'undelivered-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['balance'],
  },
  {
    name: 'Billed',
    classNameTH: 'billed-td',
    classNameTD: 'billed-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['billed'],
  },
  {
    name: 'UoM',
    classNameTH: 'uom-td',
    classNameTD: 'uom-td text-center',
    dataField: ['uom'],
  },
  {
    name: 'Unit Price',
    classNameTH: 'unit-price-td',
    classNameTD: 'unit-price-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['unit_price'],
  },
  {
    name: 'Taxes',
    classNameTH: 'taxes-td',
    classNameTD: 'taxes-td text-center',
    dataField: ['tax'],
  },
];

export const RECEIPT_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'checkbox-td text-center',
    dataField: ['reference'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },
  {
    name: 'Contact',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['contact'],
    canBeFiltered: false,
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['schedule_date'],
    canBeFiltered: false,
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['source_document'],
    canBeFiltered: true,
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['status'],
    canBeFiltered: true,
    customFormatter: ({ status }) => {
      return <div>{status}</div>;
    },
  },
];

export const RECEIPT_INNER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'Lot No',
    classNameTH: 'lotNo-td',
    classNameTD: 'lotNo-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotNo'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Received Quantity',
    classNameTH: 'received-quantity-td',
    classNameTD: 'received-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['received_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: ['received_quantity', 'lotNo', 'showEdit', 'id', 'tracking'],
    isCustom: true,
    customFormatter: ({
      showEdit,
      id,
      lotNo,
      received_quantity,
      popUp,
      setPopUp,
      setPopUpData,
      tracking
    }) => {
      return (
        <>
          {showEdit || (
            <ActionBox
              id={id}
              setPopUpData={setPopUpData}
              received_quantity={received_quantity}
              popUp={popUp}
              setPopUp={setPopUp}
              lotNo={lotNo}
              tracking= {tracking}
            />
          )}
        </>
      );
    },
  },
];

export const FABRIC_DISPATCH_INNER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'LotNo',
    classNameTH: 'lotno-td',
    classNameTD: 'lotno-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotno'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Producing Quantity',
    classNameTH: 'producing-quantity-td',
    classNameTD: 'producing-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['producing_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: ['move_id', 'lotno', 'producing_quantity', 'demand_quantity', 'edit'],
    isCustom: true,
    customFormatter: () => {
      return (
        <div className="td-num">
          <EditIcon />
        </div>
      );
    },
  },
];

export const YARN_DISPATCH_INNER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'LotNo',
    classNameTH: 'lotno-td',
    classNameTD: 'lotno-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotno'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'producing-quantity-td',
    classNameTD: 'producing-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['producing_quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  //  YARN_DISPATCH_INNER_COLUMN
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: ['producing_quantity', 'lotno', 'move_id', 'unit', 'demand_quantity', 'product'],
    isCustom: true,
    customFormatter: ({
      unit,
      move_id,
      product,
      demand_quantity,
      popUp,
      setPopUp,
      setPopUpData,
      lotno,
      producing_quantity,
    }) => {
      return (
        <ActionBox2
          unit={unit}
          move_id={move_id}
          product={product}
          setPopUpData={setPopUpData}
          demand_quantity={demand_quantity}
          popUp={popUp}
          setPopUp={setPopUp}
          lotno={lotno}
          producing_quantity={producing_quantity}
        />
      );
    },
  },
];

export const PACKAGE_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Package No',
    classNameTH: 'package-no-th',
    classNameTD: 'package-no-td text-center',
    dataField: ['package_no'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Box Count',
    classNameTH: 'box-count-th',
    classNameTD: 'box-count-td text-center',
    dataField: ['box_count'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Packaging Date',
    classNameTH: 'packaging-date-th',
    classNameTD: 'packaging-date-td',
    dataField: ['packaging_date'],
  },
  {
    name: 'Dispatch Date',
    classNameTH: 'dispatch-date-th',
    classNameTD: 'dispatch-date-td',
    dataField: ['dispatch_date'],
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      return <div>{status}</div>;
    },
  },
];

export const DISPATCH_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['shipment_no'],
  },
  {
    name: 'Delivery Contact',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['delivery_contact'],
  },
  {
    name: 'Source',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['origin'],
  },
  {
    name: 'Shipment Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['shipment_date'],
  },
  {
    name: 'Box Count',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['box_count'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Status',
    classNameTH: '',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],

    customFormatter: ({ status }) => {
      const statusClass = status === 'done' ? 'received' : 'waiting';
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const BILLS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },

  {
    name: 'Invoice',
    classNameTH: 'invoice-th',
    classNameTD: 'invoice-td text-center',
    dataField: ['name'],
    canBeFiltered: true,
  },
  {
    name: 'Source',
    classNameTH: 'invoice-th',
    classNameTD: 'invoice-td text-center',
    dataField: ['source'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },
  {
    name: 'Invoice Date',
    classNameTH: 'invoice-date-th',
    classNameTD: 'invoice-date-td text-center',
    dataField: ['invoice_date'],
    canBeFiltered: true,
  },
  {
    name: 'Due Date',
    classNameTH: 'due-date-th',
    classNameTD: 'due-date-td text-center',
    dataField: ['invoice_date_due'],
    canBeFiltered: true,
  },
  {
    name: 'Status',
    classNameTH: 'status-th',
    classNameTD: 'status-td text-center',
    dataField: ['state'],
    canBeFiltered: true,
    customFormatter: ({ status }) => {
      return <div>{status}</div>;
    },
  },
  {
    name: 'Amount Due',
    classNameTH: 'amount-due-th',
    classNameTD: 'amount-due-td text-center',
    dataField: ['amount_residual_signed'],
    sort: true,
    valueType: 'Number',
    canBeFiltered: true,
  },
];

export const BILLS_DETAILS_COLUMN = [
  {
    name: '',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'Quantity',
    classNameTH: 'quantity-td',
    classNameTD: 'quantity-td text-center',
    dataField: ['qty'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'UoM',
    classNameTH: 'uom-td',
    classNameTD: 'uom-td text-center',
    dataField: ['uom'],
  },
  {
    name: 'Price',
    classNameTH: 'price-td',
    classNameTD: 'price-td text-center',
    dataField: ['unit_price'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Tax',
    classNameTH: 'tax-td',
    classNameTD: 'tax-td text-center',
    dataField: ['tax'],
  },
  {
    name: 'SubTotal',
    classNameTH: 'tax-excl-td',
    classNameTD: 'tax-excl-td text-center',
    dataField: ['subtotal'],
  },
];
export const BATCH_COLUMN = [
  { name: 'Batch No', classNameTh: '', classNameTd: '', isCustom: false, dataField: ['name'] },
  {
    name: 'Order QTY',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['order_qty'],
  },
  { name: 'Cut QTY', classNameTh: '', classNameTd: '', isCustom: false, dataField: ['cut_qty'] },
  { name: 'Cut Date', classNameTh: '', classNameTd: '', isDate: true, dataField: ['cut_date'] },
  { name: 'Sew QTY', classNameTh: '', classNameTd: '', isCustom: false, dataField: ['sew_qty'] },
  { name: 'Sew Date', classNameTh: '', classNameTd: '', isDate: true, dataField: ['sew_date'] },
  // { name: 'Dye QTY', classNameTh: '', classNameTd: '', isCustom: false, dataField: ['dye_qty'] },
  // { name: 'Dye Date', classNameTh: '', classNameTd: '', isDate: true, dataField: ['dye_date'] },
  {
    name: 'Packed Qty',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['pack_qty'],
  },
  {
    name: 'Packed Date',
    classNameTh: '',
    classNameTd: '',
    isDate: true,
    dataField: ['pack_date'],
  },
  {
    name: 'Completed',
    classNameTh: '',
    classNameTd: '',
    isCustom: true,
    dataField: ['state'],
    customFormatter: ({ state }) => {
      return (
        <div
          className={`${state === 'packed' ? 'green' : 'orange'}-active w-auto d-flex align-items-center justify-content-center`}>
          {state === 'packed' ? 'completed' : 'In Progress'}
        </div>
      );
    },
  },
  {
    name: 'Action',
    classNameTh: '',
    classNameTd: '',
    isClickable: true,
    isCustom: true,
    dataField: ['state'],
    customFormatter: ({ state }) => {
      return state === 'packed' ? <Packages width={'18'} height={'18'} /> : <EditIcon />;
    },
  },
];

export const BLP_COLUMNS = [
  // {
  //   name: 'Lot No',
  //   classNameTh: '',
  //   classNameTd: '',
  //   dataField: ['lot_no'],
  // },
  {
    name: 'No',
    classNameTh: '',
    classNameTd: '',
    dataField: ['id'],
  },
  {
    name: 'BLP No',
    classNameTh: '',
    classNameTd: '',
    dataField: ['name'],
  },
];
