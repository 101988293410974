import React, { useState, useEffect } from 'react';
import { DropDownInput } from '../../common';
import { BlueCheckIcon, DeleteRedIcon, CheckIcon } from '../../../assets/icons';
import { Input, Button } from '../../shared';
import { popup } from '../../../utils';
import { BUTTON, POP, POPUP_TYPE, ROUTES } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { mixBoxPackage, submitSkuMixBoxPackage } from '../../../service/package';
import { setLoaderVisibility } from '../../../redux';

const MixBoxInputTable = () => {
  const [mixBoxData, setMixBoxData] = useState([]);
  const [rows, setRows] = useState([]);
  const [payload, setPayload] = useState([]);
  const [allRowsValidated, setAllRowsValidated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getMixBoxList = useDebounce(() => {
    mixBoxPackage({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setMixBoxData(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getMixBoxList();
  }, []);

  const columns = ['SKU', 'Quantity', 'Action'];
  const availableSKUs = mixBoxData.map((obj) => obj.name);

  const getAvailableQuantity = (sku) => {
    const found = mixBoxData.find((item) => item.name === sku);
    return found ? found.quantity : 0;
  };

  const addRow = () => {
    setRows([...rows, { dropdown1: '', quantity: '', isChecked: false }]);
  };

  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    if (field === 'quantity') {
      const selectedSKU = newRows[index].dropdown1;
      const availableQuantity = getAvailableQuantity(selectedSKU);

      if (Number(value) > availableQuantity) {
        popup(
          POPUP_TYPE.ERR,
          `Enter quantity less or equal to Available Quantity: ${availableQuantity}`,
        );
        value = '';
      }
    }
    newRows[index][field] = value;
    newRows[index].isChecked = false;
    setRows(newRows);
    const rowIndexInPayload = payload.findIndex((item) => item.index === index);
    if (rowIndexInPayload !== -1) {
      const newPayload = [...payload];
      newPayload[rowIndexInPayload] = { ...newRows[index], index };
      setPayload(newPayload);
    }
    validateRows(newRows);
  };

  const handleIconClick = (index) => {
    const newRows = [...rows];
    if (newRows[index].dropdown1 && newRows[index].quantity) {
      newRows[index].isChecked = !newRows[index].isChecked;
      setRows(newRows);
      if (newRows[index].isChecked) {
        setPayload([...payload, { ...newRows[index], index }]);
        popup(POPUP_TYPE.SUCCESS, POP.SKU_ADDED);
      } else {
        const newPayload = payload.filter((item) => item.index !== index);
        setPayload(newPayload);
      }
    } else {
      popup(POPUP_TYPE.ERR, POP.FILL_ALL_SKU_DETAIL);
    }
    validateRows(newRows);
  };

  const deleteRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);

    const newPayload = payload.filter((item) => item.index !== index);
    setPayload(newPayload);
    validateRows(newRows);
  };

  const validateRows = (rows) => {
    const allValid = rows.every((row) => row.dropdown1 && row.quantity && row.isChecked);
    setAllRowsValidated(allValid);
  };

  useEffect(() => {
    validateRows(rows);
  }, [rows]);

  const getFilteredSKUs = (index) => {
    const selectedSKUs = rows.map((row) => row.dropdown1).filter((sku, i) => i !== index);
    return availableSKUs.filter((sku) => !selectedSKUs.includes(sku));
  };

  const handleSubmit = () => {
    const data = rows.map((row) => {
      const sku = row.dropdown1;
      const found = mixBoxData.find((item) => item.name === sku);
      return {
        id: found.id,
        quantity: Number(row.quantity),
      };
    });
    submitSkuMixBoxPackage({
      data: { data },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
    navigate(ROUTES.PACKAGE);
  };

  return (
    <div className="data-content">
      <div className="w-100 editing-container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="edit-container py-4 px-lg-5 px-4 table-new-div bg-white data-box w-100 h-100">
            <div className="d-flex justify-content-end align-items-center mb3">
              <Button
                disabled={!allRowsValidated}
                className={`border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3 ${!allRowsValidated || payload.length === 0 ? 'disabled' : ''}`}
                clickHandler={handleSubmit}>
                {BUTTON.SUBMIT_SKU}
              </Button>
            </div>
            <div className="w-100 table-3">
              <table className="w-100">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th
                        className={
                          index == 0 ? 'first-col' : index == 1 ? 'second-col' : 'last-col'
                        }
                        key={index}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="inner-table-tbody-input">
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="custom-padding">
                        <DropDownInput
                          dropdownMenu={getFilteredSKUs(rowIndex)}
                          value={row.dropdown1}
                          onChange={(value) => handleChange(rowIndex, 'dropdown1', value)}
                        />
                      </td>
                      <td className="custom-padding">
                        <Input
                          className="qty-input"
                          placeholder={`Available Qty: ${getAvailableQuantity(row.dropdown1)}`}
                          type="text"
                          value={row.quantity}
                          onChange={(e) => handleChange(rowIndex, 'quantity', e.target.value)}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <Button
                            className="bg-white border-0 d-flex align-items-center justify-content-center p-1"
                            clickHandler={() => handleIconClick(rowIndex)}>
                            {row.isChecked ? <BlueCheckIcon /> : <CheckIcon />}
                          </Button>
                          <Button
                            className="ms-2 bg-white border-0 d-flex align-items-center justify-content-center p-1"
                            clickHandler={() => deleteRow(rowIndex)}>
                            <DeleteRedIcon />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <Button
                          clickHandler={addRow}
                          disabled={!allRowsValidated}
                          className={`border-0 blue-btn d-flex align-items-center ${!allRowsValidated ? 'disabled' : ''} justify-content-center py-2 px-3`}>
                          {BUTTON.ADD_ROW}
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MixBoxInputTable;
