import React from 'react';
import { TITLES } from '../../../constants';
import { TitleBar } from '../../common';
export const ReportsContent = () => {
  return (
    <>
      <TitleBar title={TITLES.REPORTS} />
    </>
  );
};
