import React from 'react';
import { SearchBar, Table, TitleBar } from '../../common';
import { PACKAGE_COLUMN, ROUTES, TITLES } from '../../../constants';
import { package_data } from '../../../constants';
export const PackageContent = () => {
  const to = ROUTES.PACKAGE_DETAILS;
  return (
    <>
      <TitleBar title={[{ text: TITLES.PACKAGE, route: ROUTES.PACKAGE }]} />
      <SearchBar />
      <Table
        columns={PACKAGE_COLUMN}
        initialData={package_data}
        PAGE_SIZE={10}
        colFilter={true}
        to={to}
      />
    </>
  );
};
