import { useEffect, useRef } from 'react';

export const useDebounce = (callback, delay) => {
  const timerRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const debouncedCallback = (...args) => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
};
