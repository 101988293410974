import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, Sidebar, ContentBox } from './index.js';
import { ROUTES } from '../../constants';
import PropTypes from 'prop-types';

export const Layout = () => {
  const [currentDashboardRoute, setCurrentDashboardRoute] = useState(ROUTES.DASHBOARD);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === ROUTES.HOME) navigate(ROUTES.DASHBOARD);
    setCurrentDashboardRoute(location.pathname);
  }, [location.pathname]);

  const handleUserPreference = () => {
    // Implement user preference action
  };

  const handleLogout = () => {
    // Implement logout action
  };

  return (
    <main className="main-page-area">
      <Header onUserPreference={handleUserPreference} onLogout={handleLogout} />
      <div className="main-view-area d-flex align-items-start justify-content-start position-relative">
        <Sidebar
          currentDashboardRoute={currentDashboardRoute}
          setCurrentDashboardRoute={setCurrentDashboardRoute}
        />
        <ContentBox currentRoute={currentDashboardRoute} />
      </div>
    </main>
  );
};

Layout.propTypes = {};

Sidebar.propTypes = {
  currentDashboardRoute: PropTypes.string.isRequired,
  setCurrentDashboardRoute: PropTypes.func.isRequired,
};

ContentBox.propTypes = {
  currentRoute: PropTypes.string.isRequired,
};

export default Layout;
