import React from 'react';
import { dateFormatChanger } from '../../../../utils';

export const MfgTable = ({ column, data, clickHandler, dyeing }) => {
  const filteredColumn = dyeing ? column : column.filter((col) => !col.hideIfNonDye);
  return (
    <table className=" w-100">
      <thead>
        <tr>
          {filteredColumn.map((col, index) => (
            <th key={index} className="text-center">
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            className="text-center"
            key={rowIndex}
            style={{ cursor: 'pointer', color: 'black', fontSize: '12px', fontWeight: '600' }}>
            {filteredColumn.map((col, colIndex) => (
              <td
                onClick={
                  col.isClickable
                    ? (e) => {
                        clickHandler({ row, e });
                      }
                    : () => {}
                }
                key={colIndex}
                className={col.classNameTD}>
                {col.isCustom
                  ? col.customFormatter(
                      col.dataField.reduce((acc, field) => {
                        acc[field] = row[field];
                        return acc;
                      }, {}),
                    )
                  : col.isDate
                    ? dateFormatChanger(row[col.dataField])
                    : row[col.dataField]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
