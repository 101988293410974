import React from 'react';

export const StatusBox = ({ status }) => {
  return (
    <div className="d-flex align-items-center justify-content-end mb-3">
      {status.map((value) => (
        <div
          key={value.name}
          className="me-2 color-label-container d-flex align-items-center justify-content-end"
        >
          <div className={`color-box ${value.className} me-1`}></div>
          <span>{value.name}</span>
        </div>
      ))}
    </div>
  );
};
