import React, { useEffect, useState, useRef } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import {
  TITLES,
  ROUTES,
  YARN_DISPATCH_INNER_COLUMN,
  POPUP_TYPE,
  POPUP_MSG,
  BUTTON,
} from '../../../constants';
import { useLocation } from 'react-router-dom';
import { PopUpBox, Button } from '../../shared';

import {
  dispatchDetailsBox,
  fabricDispatchProductCompiler,
} from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { updateReceiptQty, vendorReceipt } from '../../../service/receipt';
import { setLoaderVisibility } from '../../../redux';
import { popup, getLocalStorageItem } from '../../../utils';

export const YarnDispatchDetail = () => {
  const dispatch = useDispatch();
  const { state } = useLocation(location?.state);
  const [productData, setProductData] = useState([]);
  const [validate, setValidate] = useState(false);
  const [popUpData, setPopUpData] = useState('');

  const getProductsList = useDebounce(() => {
    vendorReceipt({
      data: { receipt_ids: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setProductData(response.data[0]?.move_ids);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, []);

  const validateDispatch = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const column =
    state?.state !== 'assigned'
      ? YARN_DISPATCH_INNER_COLUMN.filter((col) => col.name !== 'Action')
      : YARN_DISPATCH_INNER_COLUMN;
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state.name, route: null },
        ]}
      />
      <div className="scroll-div">
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <Table
          columns={column}
          initialData={productData}
          PAGE_SIZE={4}
          colFilter={false}
          compiler={{ use: true, tool: fabricDispatchProductCompiler }}
          popUp={validate}
          setPopUp={setValidate}
          setPopUpData={setPopUpData}
        />
        {validate && (
          <PopUpBox openConfirmationBox={validate}>
            <YarnDispatchValidate
              apiCall={validateDispatch}
              setOpenConfirmationBox={setValidate}
              data={popUpData}
            />
          </PopUpBox>
        )}
      </div>
    </>
  );
};
const YarnDispatchValidate = ({ apiCall, setOpenConfirmationBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const [lotNo, setLotNo] = useState('');
  const qtyRef = useRef();
  const lotRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenConfirmationBox(false);
      const apiData =
        data.lotno === 'N/A' && getLocalStorageItem('tags')[0] !== 'yarn'
          ? { move_id: data.move_id, done_qty: +doneQty }
          : {
              move_id: data.move_id,
              done_qty: +doneQty,
              lot_name: lotNo,
            };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    if (lotRef.current) {
      lotRef.current.focus();
    } else {
      qtyRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h5 className=" text-center text-special mb-3 "> {data.product} </h5>
        <div className="w-100 modal-body py-0 d-flex flex-column gap-3 justify-content-center align-items-center">
          {(data.lotno === 'N/A' && getLocalStorageItem('tags')[0] !== 'yarn') || (
            <input
              type="text"
              className="ps-2 curve-border"
              placeholder={data.lotno || 'Enter lot Number'}
              ref={lotRef}
              onChange={(e) => {
                setLotNo(e.target.value);
              }}
            />
          )}
          <input
            type="number"
            className="ps-2 curve-border placeholder-text"
            placeholder={data.producing_quantity || 'Enter dispatched quantity'}
            ref={qtyRef}
            onChange={(e) => {
              setDoneQty(e.target.value);
            }}
          />
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
