export function reportDataCompiler(data) {
  let finalData = [];
  data.map((report) => {
    if (Object.keys(report.fp_batch_data).length > 0) {
      for (let key in report.fp_batch_data) {
        let reportData = {
          po: report.po_name,
          lot: report?.future_lot || 'N/A',
          pf_name: report?.pfd_name || 'N/A',
          pf_color: report?.pfd_color || 'N/A',
          pf_size: report.pfd_size || 'N/A',
          pf_ordered_qty: report?.pfd_ordered_qty || 'N/A',
          pf_produced_qty:
            report.pfd_produced_data[Object.keys(report.pfd_produced_data)[0]]?.produced_qty ||
            'N/A',
          pf_produced_date: report.pf_produced_date?.split(' ')[0] || 'N/A',
          rib_name: report.rib_name || 'N/A',
          rib_ordered_qty: report.rib_ordered_qty || 'N/A',
          rib_produced_qty:
            report.rib_produced_data[Object.keys(report.rib_produced_data)[0]]?.produced_qty ||
            'N/A',
          rib_produced_date: report.rib_produced_date?.split(' ')[0] || 'N/A',
          ip_name: report.ip_name || 'N/A',
          ip_ordered_qty: report.ip_ordered_qty || 'N/A',
          ip_cut_qty: report?.ip_cut_qty || 'N/A',
          ip_cut_date: report?.ip_cut_date?.split(' ')[0] || 'N/A',
          ip_sew_qty: report?.ip_sew_qty || 'N/A',
          ip_sew_date: report?.ip_sew_date?.split(' ')[0] || 'N/A',
          fp_name: report.fp_name || 'N/A',
          fp_ordered_qty: report.fp_ordered_qty || 'N/A',
          fp_packed_qty: report.fp_batch_data[key].pack_qty || 'N/A',
          fp_cut_qty: report.fp_batch_data[key].cut_qty || 'N/A',
          fp_cut_date: report.fp_batch_data[key].cut_date?.split(' ')[0] || 'N/A',
          fp_sew_qty: report.fp_batch_data[key].sew_qty || 'N/A',
          fp_sew_date: report.fp_batch_data[key].sew_date?.split(' ')[0] || 'N/A',
          ip_packed_qty: report.ip_packed_qty || 'N/A',
          ip_packed_date: report.ip_packed_date?.split(' ')[0] || 'N/A',
        };
        finalData.push(reportData);
      }
    } else if (Object.keys(report.ip_batch_data).length > 0) {
      for (let key in report.ip_batch_data) {
        let reportData = {
          po: report.po_name,
          lot: report?.future_lot || 'N/A',
          pf_name: report?.pfd_name || 'N/A',
          pf_color: report?.pfd_color || 'N/A',
          pf_size: report.pfd_size || 'N/A',
          pf_ordered_qty: report?.pf_ordered_qty || 'N/A',
          pf_produced_qty:
            report.pfd_produced_data[Object.keys(report.pfd_produced_data)[0]]?.produced_qty ||
            'N/A',
          pf_produced_date: report.pf_produced_date?.split(' ')[0] || 'N/A',
          rib_name: report.rib_name || 'N/A',
          rib_ordered_qty: report.rib_ordered_qty || 'N/A',
          rib_produced_qty:
            report.rib_produced_data[Object.keys(report.rib_produced_data)[0]]?.produced_qty ||
            'N/A',
          rib_produced_date: report.rib_produced_date?.split(' ')[0] || 'N/A',
          ip_name: report.ip_name || 'N/A',
          ip_ordered_qty: report.ip_ordered_qty || 'N/A',
          ip_cut_qty: report.ip_batch_data[key].cut_qty || 'N/A',
          ip_cut_date: report.ip_batch_data[key].cut_date?.split(' ')[0] || 'N/A',
          ip_sew_qty: report.ip_batch_data[key].sew_qty || 'N/A',
          ip_sew_date: report.ip_sew_date?.split(' ')[0] || 'N/A',
          fp_name: report.fp_name || 'N/A',
          fp_ordered_qty: report.fp_ordered_qty || 'N/A',
          fp_packed_qty: report.fp_batch_data[key].pack_qty || 'N/A',
          fp_cut_qty: report.fp_batch_data[key].cut_qty || 'N/A',
          fp_cut_date: report.fp_batch_data[key].cut_date?.split(' ')[0] || 'N/A',
          fp_sew_qty: report.fp_batch_data[key].sew_qty || 'N/A',
          fp_sew_date: report.fp_batch_data[key].sew_date?.split(' ')[0] || 'N/A',
          ip_packed_qty: report.ip_batch_data[key].pack_qty || 'N/A',
          ip_packed_date: report.ip_batch_data[key].pack_date?.split(' ')[0] || 'N/A',
        };
        finalData.push(reportData);
      }
    } else {
      let reportData = {
        po: report.po_name,
        lot: report?.future_lot || 'N/A',
        pf_name: report?.pfd_name || 'N/A',
        pf_color: report?.pfd_color || 'N/A',
        pf_size: report.pfd_size || 'N/A',
        pf_ordered_qty: report?.pf_ordered_qty || 'N/A',
        pf_produced_qty:
          report.pfd_produced_data[Object.keys(report.pfd_produced_data)[0]]?.produced_qty || 'N/A',
        pf_produced_date: report.pf_produced_date?.split(' ')[0] || 'N/A',
        rib_name: report.rib_name || 'N/A',
        rib_ordered_qty: report.rib_ordered_qty || 'N/A',
        rib_produced_qty:
          report.rib_produced_data[Object.keys(report.rib_produced_data)[0]]?.produced_qty || 'N/A',
        rib_produced_date: report.rib_produced_date?.split(' ')[0] || 'N/A',
        ip_name: report.ip_name || 'N/A',
        ip_ordered_qty: report.ip_ordered_qty || 'N/A',
        ip_cut_qty: 'N/A',
        ip_cut_date: 'N/A',
        ip_sew_qty: 'N/A',
        ip_sew_date: 'N/A',
        fp_name: report.fp_name || 'N/A',
        fp_ordered_qty: report.fp_ordered_qty || 'N/A',
        fp_packed_qty: 'N/A',
        fp_cut_qty: 'N/A',
        fp_cut_date: 'N/A',
        fp_sew_qty: 'N/A',
        fp_sew_date: 'N/A',
        ip_packed_qty: 'N/A',
        ip_packed_date: 'N/A',
      };
      finalData.push(reportData);
    }
  });
  return finalData;
}
