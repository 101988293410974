import React from 'react';
const Packages = ({ width, height }) => (
  <svg
    width={width || 26}
    height={height || 26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_246_2137)">
      <path
        d="M15.8921 4.80029V4.79404L20.5702 6.88936L25.2671 5.12842L12.7671 0.440918L0.26709 5.12842L12.7671 9.81592L17.4452 8.06123L12.7671 6.13311V6.12842L15.8921 4.80029Z"
        fill="#707070"
      />
      <path
        d="M0.26709 6.69092V20.7534L11.9858 25.1487V11.0862L0.26709 6.69092ZM6.51709 20.805L3.39209 19.6331V17.9659L6.51709 19.1378V20.805Z"
        fill="#707070"
      />
      <path
        d="M20.5796 8.44873V12.3628L17.4546 13.5347V9.62061L13.5483 11.0862V25.1487L25.2671 20.7534V6.69092L20.5796 8.44873Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_246_2137">
        <rect width={25} height={25} fill="white" transform="translate(0.26709 0.294922)" />
      </clipPath>
    </defs>
  </svg>
);
export default Packages;
