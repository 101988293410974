export function receiptCompiler(data) {
  let finalData = [];
  data.map((receipt) => {
    let receiptData = {
      url: receipt.name.replaceAll('/', '_'),
      id: receipt.id,
      reference: receipt.name,
      from: receipt.location_id.display_name,
      to: receipt.location_dest_id.display_name,
      contact: receipt.partner_id.name || 'N/A',
      status: receipt.state,
      source_document: receipt.origin || '',
      schedule_date: receipt.scheduled_date,
      deadline: receipt.date_deadline,
      data: receipt,
    };
    finalData.push(receiptData);
  });
  return finalData;
}

export function receiptDetailCompiler({ moveIds, state }) {
  let finalData = [];
  moveIds?.map((move) => {
    let receiptDetailData = {
      id: move?.id,
      product: move?.product_id.display_name,
      lotNo:
        move?.product_id.tracking === 'lot'
          ? move.lot_ids.length > 0
            ? move.lot_ids[0].name
            : move.move_line_ids[0]?.lot_name
          : 'N/A',
      demand_quantity: move?.product_uom_qty,
      received_quantity: move?.quantity,
      unit: move?.product_uom.name,
      tracking: move?.product_id?.tracking,
      lot_ids: move?.lot_ids,
      move_line_ids: move?.move_line_ids,
      state: state,
      showEdit: move?.subcontract_has_been_recorded,
    };
    finalData.push(receiptDetailData);
  });
  return finalData;
}

export function receiptDetailsBox(receipt) {
  let finalData = [
    { name: 'Destination Location', col: 1, data: receipt?.location_dest_id.display_name },
    { name: 'Receive From', col: 1, data: receipt?.location_id.display_name },
    { name: 'Scheduled Date', col: 2, data: receipt?.scheduled_date },
    { name: 'Deadline', col: 2, data: receipt?.date_deadline || 'N/A' },
    { name: 'Source Document', col: 2, data: receipt?.origin || 'N/A' },
  ];
  return finalData;
}
