import React from 'react';
import { DatePickerBox } from '../../components/common';
import { BUTTON } from '../../constants';
export const billDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let billData = {
      url: obj.name.replaceAll('/', '_'),
      name: obj.name,
      source: obj.invoice_origin,
      invoice_date: obj.invoice_date,
      invoice_date_due: obj.invoice_date_due,
      state: obj.state,
      amount_residual_signed: Math.abs(obj.amount_residual_signed).toFixed(2),
      data: obj,
    };
    finalData.push(billData);
  });
  return finalData;
};

export const billDetailsCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let billDetailsData = {
      product: obj.name,
      qty: obj.quantity,
      uom: obj.product_uom_id.name,
      unit_price: obj.price_unit,
      tax: obj.tax_ids[0].name,
      subtotal: obj.price_subtotal.toFixed(2),
    };
    finalData.push(billDetailsData);
  });
  return finalData;
};

export function billsDetailBox(bill) {
  let detailBoxData = [
    { name: 'Bill No', col: 1, data: bill?.name },
    { name: ' Name', col: 1, data: bill?.partner_id?.name },
    {
      name: 'Bill Date',
      col: 1,
      isCustom: bill?.state === 'draft' ? true : false,
      data: bill?.invoice_date,
      view: ({ value, setValue, clickHandler, showSave }) => {
        return (
          <div key={bill?.name} className="row mb-3">
            <div className="col-6 title">{'Bill Date'}</div>
            <div className="col-6 d-flex align-items-center gap-3">
              <DatePickerBox
                value={value}
                setValue={setValue}
                clickHandler={clickHandler}
                showSave={showSave}
                btnName={BUTTON.UPDATE}
              />
            </div>
          </div>
        );
      },
    },
  ];
  return detailBoxData;
}
