import * as React from 'react';
const D7 = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.5001 3.29492H6.90833C5.30839 3.29492 4 4.59497 4 6.20325V20.3866C4 21.9867 5.30839 23.2949 6.90833 23.2949H18.5001C20.1001 23.2949 21.4082 21.9867 21.4082 20.3866V6.20325C21.4082 4.59497 20.1001 3.29492 18.5001 3.29492ZM12.6595 20.244H8.68567C8.18823 20.244 7.7856 19.8411 7.7856 19.3441C7.7856 18.8471 8.18823 18.4443 8.68567 18.4443H12.6595C13.1569 18.4443 13.5593 18.8471 13.5593 19.3441C13.5593 19.8411 13.1569 20.244 12.6595 20.244ZM16.7212 15.9451H8.68567C8.18823 15.9451 7.7856 15.542 7.7856 15.0452C7.7856 14.5482 8.18823 14.1451 8.68567 14.1451H16.7212C17.2186 14.1451 17.6212 14.5482 17.6212 15.0452C17.6212 15.542 17.2186 15.9451 16.7212 15.9451ZM17.9154 7.84123L15.0286 11.059C14.7343 11.3868 14.2456 11.4517 13.8749 11.2167L11.0742 9.43058L8.81038 11.7926C8.46655 12.1519 7.89635 12.1632 7.53882 11.8199C7.17932 11.4759 7.16711 10.9064 7.51156 10.5475L10.2858 7.65222C10.5839 7.34257 11.0583 7.28621 11.4194 7.51611L14.1992 9.28857L16.5763 6.63904C16.9083 6.26917 17.478 6.24007 17.847 6.57048C18.2169 6.9023 18.2476 7.47135 17.9154 7.84123Z"
      fill="#4B5564"
    />
  </svg>
);
export default D7;
