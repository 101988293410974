// Pop Messages
export const POP = {
  BILL_CREATED: 'Bill Created in Draft Mode',
  ALL_BOXES_SCANNED: 'All boxes scanned in this shipment',
  SKU_ADDED: 'SKU added successfully',
  FILL_ALL_SKU_DETAIL: 'Please fill all details of SKU',
};

//Button texts
export const BUTTON = {
  CREATE_PACKAGE_AND_VALIDATE: 'Create Package and Validate',
  SUBMIT: 'Submit',
  BACK: 'Back',
  SCAN_BOX: 'Scan Box',
  CREATE_DISPATCH: 'Create Dispatch',
  SIGN_IN: 'Sign In',
  CREATE_BATCH: 'Create Batch',
  CLOSE: 'Close',
  SCAN_BLP: 'Scan BLP',
  SUBMIT_SKU: 'Submit SKUs',
  ADD_ROW: 'Add Row',
  PRINT_LABEL: 'Print Label',
  ADD_BOX: 'Add Box',
  ADD_BILL: 'Add Bill',
  GENERATE_BLP: 'Generate BLP Labels',
  CANCEL: 'Cancel',
  VALIDATE: 'Validate',
  UPDATE: 'Update',
  CONFIRM: 'Confirm',
  READY_FOR_PACK: 'Ready for Packing',
  READY_FOR_DYEING: 'Ready for Dyeing',
  SAVE: 'Save',
  SEARCH: 'Search',
  CANCEL_DISPATCH: 'Cancel Dispatch',
  CLEAR_FILTERS: 'Clear Filters',
  STOP_SCAN: 'Stop Scan',
  DISPATCH: 'Dispatch',
};

// Other texts
export const TEXT = {
  NO_BATCH_AVAILABLE: 'No Batches Available, To add New batches click Below Button',
  BATCH_NO: 'Batch No',
  ORDER_QTY: 'Order Qty',
  CUT_QTY: 'Cut Quantity',
  CUT_DATE: 'Cut Date',
  SEW_QTY: 'Sew Quantity',
  SEW_DATE: 'Sew Date',
  DYE_QTY: 'Dye Quantity',
  DYE_DATE: 'Dye Date',
  PACKAGE_QTY: 'Packaged Quantity',
  PACKAGE_DATE: 'Packaged Date',
  ORDER_STATUS: 'Order Status',
  READY: 'Ready For Packaging',
  NO_ACCOUNT: 'Don’t have an account?',
  NO_LOGOUT: 'Unable to Logout at this moment',
  BOX_SIZE: 'Box Size',
  BOX_TYPE: 'Box Type',
};

// Heading texts
export const HEADING = {
  BATCH_CREATION: 'Batch Creation',
  EDIT_BATCH: 'Edit Batch',
  BOX_COUNT: 'Box Count',
};

// Routes
export const ROUTES = {
  HOME: '/',
  EMPTY: '',
  LOGIN: '/login',
  SERVER_ERROR: '/server_error',
  DASHBOARD: '/dashboard',
  PURCHASE_ORDER: '/purchase_order',
  PO_DETAILS: '/purchase_order_detail',
  BILLS: '/bills',
  BILL_DETAILS: '/bill_detail',
  CREATE_BILL: '/create_bill',
  RECEIPT: '/receipt',
  RECEIPT_DETAILS: '/receipt_detail',
  PRODUCTION: 'production',
  MANUFACTURING: '/manufacturing',
  LOT_DETAILS: '/lot_details',
  BLP_OVERVIEW: '/blp_overview',
  PACKAGE: '/package',
  PACKAGE_BLP_OVERVIEW: '/package_blp',
  ADD_MIX_BOX: '/add_mix_box',
  MIX_BOX_SUMMARY: '/mix_box_summary',
  PACKAGE_DETAILS: '/package_detail',
  DISPATCH: '/dispatch',
  DISPATCH_BLP_OVERVIEW: '/dispatch_blp',
  CREATE_DISPATCH: '/create_dispatch',
  DISPATCH_DETAILS: '/dispatch_detail',
  FABRIC_DISPATCH_DETAIL: '/fabric_dispatch_detail',
  YARN_DISPATCH_DETAIL: '/yarn_dispatch_detail',
  REPORTS: '/reports',
  URL: '/:url',
  NULL: '/',
};

// methods
export const METHODS = {
  UPDATE: 'UPDATE',
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH',
};

// headers
export const HEADERS = {
  APPLICATION_JSON: 'application/json',
  AUTHORIZATION: 'Bearer',
};

// API Endpoints
export const API = {
  VENDOR_LOGIN: '/login',
  VENDOR_LOGOUT: '/logout',
  RECEIPT: '/receipt',
  PURCHASE: '/purchase',
  BILLS: '/bill',
  DISPATCH: '/dispatch',
  SHIPMENT: '/shipment',
  MANUFACTURING: '/manufacture/receipt',
  CREATE_DISPATCH: '/dispatch/create',
  DELETE_DISPATCH: '/dispatch/cancel',
  SUBMIT_DISPATCH: '/dispatch/marked',
  MODAL_DISPATCH: '/dispatch/move_details',
  MODAL_SUBMIT_DISPATCH: '/dispatch/mark_production',
  PACKAGE: '/packages',
  CREATE_BILL: '/purchase/bill/create',
  UPDATE_BILL: '/bill/update',
  LOT_DETAILS: '/manufacture/get_batch_lot',
  BATCH_CREATION: '/manufacture/batch/create',
  QTY_UPDATE: '/manufacture/update/quantity',
  READY_TO_PACK: '/manufacture/ready_for_packaging',
  READY_TO_DYE: '/manufacture/complete/batch',
  PRODUCT_IMAGE: '/manufacture/product/image',
  DISPATCH_VALIDATE: '/dispatch/validate',
  RECEIPT_UPDATE: '/receipt/move/update',
  MIX_BOX_PACKAGE: '/get/mix_packages_details',
  SUBMIT_SKU_MIX_BOX_PACKAGE: '/create/package',
  PRINT_LABEL_PACKAGE: '/print/label',
  BATCH_LIST: '/manufacture/lot_details',
  PRINT_LABEL: '/print/label',
  READY_FOR_DISPATCH: '/dispatch/mark_ready_for_dispatch',
  BLP_LIST: '/manufacturing/get_packages_for_batch',
  DISPATCH_STATUS: '/dispatch/status_change',
  READY_FOR_PACKAGE_PICKING: '/dispatch/move_packaging',
  REPORT:'/wip/report'
};

// Status Codes
export const HTTP_CODES = { INVALID_TOKEN: 498, INTERNAL_SERVER_ERROR: 500, OK: 200 };

// Tiles
export const TILES = {
  PURCHASE_ORDER: 'Order',
  BILLS: 'Bills',
  RECEIPT: 'Receipt',
  MANUFACTURING: 'Manufacturing',
  PACKAGE: 'Package',
  DISPATCH: 'Dispatch',
  REPORTS: 'Report',
};

// Titles
export const TITLES = {
  RECEIPT: 'Receipts',
  RECEIPT_INNER: 'Receipt',
  BILLS: 'Bills',
  BILL_DETAILS: 'Bill',
  REPORTS: 'WIP Report',
  PURCHASE_ORDER: 'Order',
  PURCHASE_ORDER_INNER: 'Order (P00060)',
  PACKAGE: 'Packages',
  MANUFACTURING: 'Manufacturing',
  DISPATCH: 'Dispatch',
  CREATE_DISPATCH: 'Create Dispatch',
  BLP_OVERVIEW: 'BLP',
  ADD_BOX: 'Add Box',
  CREATE_BILL: 'Create Bill',
};

// Manufacturing Status
export const MFG_STATUS = [
  {
    name: 'Completed',
    className: 'light-green',
  },
  {
    name: 'Partially Done',
    className: 'yellow',
  },
  {
    name: 'Pending',
    className: 'bright-orange',
  },
];

// Local Storage
export const LOCAL_STORAGE = {
  AUTH_TOKEN: 'auth_token',
  ALLOWED_APP: 'allowed_apps',
  TAGS: 'tags',
  USERNAME: 'username',
  VENDOR_TYPE: 'vendor_type'
};

// Packaging Status
export const PKJ_STATUS = [
  {
    name: 'Normal Box',
    className: 'blue-shade',
  },
  {
    name: 'Mix Box',
    className: 'pink-shade',
  },
];

export const DROPDOWN = {
  PO_SORT_1: ['To Invoice', 'Invoiced', 'No'],
  PO_SORT_2: ['Pending', 'Partial', 'Full'],
  BILL_SORT: ['Draft'],
  MFG_SORT_1: ['Priority', 'Make First Order', 'Low Priority'],
  MFG_SORT_2: [' Pending', 'Completed', 'Partially Done'],
  PKJ_SORT_1: ['Normal Box', 'Mix Box'],
  PKJ_SORT_2: ['BLP', 'SKU'],
  BOX_OPTION: ['12_4', '4_6'],
};

export const VALIDATION = {
  CREDENTIAL_MISMATCH: 'Email ID or Password does not Match',
};

export const ERROR = {
  INVALID_LOCAL_STORAGE_KEY_OR_VALUE: 'Invalid Key value Pair',
};

export const DISPLAY_TEXTS = {
  FABRIC_DISPATCH: [
    'Record Production Quantity',
    'Lot No:',
    'Demand Quantity:',
    'Producing Quantity:',
  ],
  BLP_TILES: ['BLP-', 'SKU-', 'Batch-'],
  CREATE_DISPATCH: ['Shipment No -', 'Box Count'],
  FABRIC_DISPATCH_MODAL: ['Product', 'Lot No.', 'Quantity'],
};
export const POPUP_MSG = {
  ENTER_QTY: ' Enter Received Quantity ',
  SURE: 'Are You Sure ?',
  QTY_WILL_SAVE: 'Quantity will be Saved Permanently',
  SUBMIT_DISPATCH_WARNING:
    'Once the dispatch has been submitted, scanning and adding additional packages to that dispatch will no longer be permitted',
  INVALID_QTY: 'Invalid Quantity',
  NOT_DISPATCHED: 'Product not Dispatched from',
  MAX_QTY: 'Max allowed Quantity',
  IMAGE_NOT_FOUND: 'Product Image Not Found',
  TOKEN_EXPIRED: 'Token Expired',
};
export const POPUP_TYPE = {
  ERR: 'error',
  SUCCESS: 'success',
};
export const DATA_FIELD = {
  RECEIVED: 'received',
  BILLED: 'billed',
  QTY: 'quantity',
  PRICE_SUM: 'price_subtotal',
  PACKED: 'packed',
  ORDER_QTY: 'order_qty',
};
export const APPS = {
  DASHBOARD: 'dashboard',
  PURCHASE: 'purchase',
  BILL: 'bill',
  RECEIPT: 'receipt',
  MFG: 'manufacture',
  PROD: 'production',
  PACK: 'packages',
  DISPATCH: 'dispatch',
  REPORT: 'report',
};
export const STATUS = {
  FAIL: 'fail',
  PASS: 'pass',
  READY: 'ready',
  CUT: 'cut',
  SEW: 'sew',
  DYE: 'dye',
  PACK: 'pck',
};
// export const DB = 'demo_shakawear';
export const DB = 'shaka_db_30_09';

export const FORM_FIELD = {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  SIGN_UP: 'Sign up',
  LOG_OUT: ' Log Out',
};
export const PLACEHOLDER = {
  ENTER_BATCH_QTY: 'Enter Batch Quantity',
  ENTER_DATE: 'Enter Date',
  ENTER_DISPATCH_QTY: 'Enter Dispatched Quantity',
};
export const SORTING = {
  DEFAULT: 'Default',
  ASCENDING: 'Asc',
  DESCENDING: 'Dsc',
};

export const VENDOR = {
  FABRIC: 'fabric',
  CLOTH: 'clothing',
  YARN: 'yarn',
  DYE: 'dyeing',
  LABEL: 'labels',
};
