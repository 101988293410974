import React from 'react';
import { Separator } from '../../../../assets/icons';

export const StatusBar = ({ lot }) => {
  const statusTab = [
    {
      key: 'Lot No',
      value: lot?.lot_no,
    },
    { key: 'SKU', value: lot?.SKU },
    { key: 'Order Unit', value: lot?.total_qty },
    { key: 'Ready Unit', value: lot?.ready_qty },
  ];
  return (
    <div className="w-100 info-header d-flex align-items-center justify-content-start flex-wrap">
      {statusTab.map((tab) => (
        <div
          key={tab.key}
          className="bg-white info-txt-box d-flex align-items-center justify-content-center">
          <span>{tab.key}</span>
          <Separator />
          <span>{tab.value}</span>
        </div>
      ))}
    </div>
  );
};
