import React from 'react';
import ErrorIcon from '../../../assets/icons/ErrorIcon';

export const Error404 = () => {
  return (
    <>
      <ErrorIcon />
    </>
  );
};
