import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import {
  Login,
  DashboardContent,
  DispatchContent,
  ManufacturingContent,
  PackageContent,
  PoContent,
  ReportsContent,
  ReceiptContent,
  BillsContent,
  DispatchDetail,
  LotInfo,
  ReceiptDetails,
  BillsDetails,
  PoDetails,
  MixBox,
  CreateDispatch,
  FabricDispatchDetail,
  PackageBlpOverview,
  YarnDispatchDetail,
  DispatchBlpOverview,
} from './components/pages';
import { LOCAL_STORAGE, ROUTES } from './constants';
import { Layout } from './components/common/Layout';
import { getLocalStorageItem } from './utils';

const VendorAuthGuard = () => {
  const authToken = getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN);
  return authToken === null ? <Login /> : <Navigate to={ROUTES.DASHBOARD} replace />;
};

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <VendorAuthGuard />,
  },
  {
    path: ROUTES.EMPTY,
    element: <VendorAuthGuard />,
  },
  {
    path: ROUTES.EMPTY,
    element: <Layout />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: (
          <VendorAuthGuard>
            <DashboardContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.PURCHASE_ORDER,
        element: <PoContent />,
      },
      {
        path: `${ROUTES.PURCHASE_ORDER}${ROUTES.URL}`,
        element: <PoDetails />,
      },
      {
        path: ROUTES.BILLS,
        element: <BillsContent />,
      },
      {
        path: `${ROUTES.BILLS}${ROUTES.URL}`,
        element: <BillsDetails />,
      },
      {
        path: ROUTES.RECEIPT,
        element: <ReceiptContent />,
      },
      {
        path: `${ROUTES.RECEIPT}${ROUTES.URL}`,
        element: <ReceiptDetails />,
      },
      {
        path: ROUTES.MANUFACTURING,
        element: <ManufacturingContent />,
      },
      {
        path: `${ROUTES.MANUFACTURING}${ROUTES.URL}`,
        element: <LotInfo />,
      },
      {
        path: ROUTES.PACKAGE,
        element: <PackageContent />,
      },
      {
        path: ROUTES.PACKAGE_BLP_OVERVIEW,
        element: <PackageBlpOverview />,
      },
      {
        path: ROUTES.DISPATCH_BLP_OVERVIEW,
        element: <DispatchBlpOverview />,
      },
      {
        path: ROUTES.ADD_MIX_BOX,
        element: <MixBox />,
      },
      {
        path: ROUTES.DISPATCH,
        element: <DispatchContent />,
      },
      {
        path: ROUTES.CREATE_DISPATCH,
        element: <CreateDispatch />,
      },
      {
        path: `${ROUTES.DISPATCH_DETAILS}${ROUTES.URL}`,
        element: <DispatchDetail />,
      },
      {
        path: `${ROUTES.FABRIC_DISPATCH_DETAIL}${ROUTES.URL}`,
        element: <FabricDispatchDetail />,
      },
      {
        path: `${ROUTES.YARN_DISPATCH_DETAIL}${ROUTES.URL}`,
        element: <YarnDispatchDetail />,
      },
      {
        path: ROUTES.REPORTS,
        element: <ReportsContent />,
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
