import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import {
  TITLES,
  RECEIPT_INNER_COLUMN,
  ROUTES,
  BUTTON,
  POPUP_MSG,
  POPUP_TYPE,
} from '../../../constants';
import { useLocation } from 'react-router-dom';
import {
  receiptDetailCompiler,
  receiptDetailsBox,
} from '../../../utils/dataCompiler/receiptDataCompiler';
import { PopUpBox } from '../../shared/PopUpBox';
import { Button } from '../../shared';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { updateReceiptQty, validateDispatch, vendorReceipt } from '../../../service';
import { popup } from '../../../utils';

export const ReceiptDetails = () => {
  const { state } = useLocation(location.state);
  const [popUp, setPopUp] = useState(false);
  const [validate, setValidate] = useState(false);
  const [popUpData, setPopUpData] = useState('');
  const [receiptData, setReceiptsData] = useState(state);
  const data = receiptDetailCompiler({ moveIds: receiptData?.move_ids, state: receiptData?.state });
  const canShowValidateButton = shouldShowValidateButton(data);

  const column = canShowValidateButton
    ? RECEIPT_INNER_COLUMN
    : RECEIPT_INNER_COLUMN.filter((col) => col.name !== 'Action');

  const dispatch = useDispatch();

  const reloadData = () => {
    vendorReceipt({
      data: {
        id: receiptData?.id,
      },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        response.data.length > 0
          ? setReceiptsData(response.data[0])
          : setReceiptsData(response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const validateReceipt = (data) => {
    dispatch(setLoaderVisibility(true));
    validateDispatch({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        reloadData();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const updateReceipt = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        reloadData();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.RECEIPT, route: ROUTES.RECEIPT },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-end gap-2 mb-3">
          {canShowValidateButton && (
            <Button
              clickHandler={() => setValidate(true)}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {BUTTON.VALIDATE}
            </Button>
          )}
        </div>
        <DetailBox colCount={2} colData={receiptData} dataCompiler={receiptDetailsBox} />
        <Table
          columns={column}
          initialData={data}
          PAGE_SIZE={4}
          colFilter={false}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
        {validate && (
          <PopUpBox openConfirmationBox={validate}>
            <ReceiptValidate
              apiCall={validateReceipt}
              setOpenConfirmationBox={setValidate}
              id={receiptData.id}
            />
          </PopUpBox>
        )}
        {popUp && (
          <PopUpBox openConfirmationBox={popUp}>
            <QtyUpdate apiCall={updateReceipt} setOpenConfirmationBox={setPopUp} data={popUpData} />
          </PopUpBox>
        )}
      </div>
    </>
  );
};

const QtyUpdate = ({ apiCall, setOpenConfirmationBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const [lot, setLot] = useState('');
  const qtyRef = useRef();
  const lotRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenConfirmationBox(false);
      const apiData= data.tracking==='lot'?{
        move_id: data.id,
        done_qty: +doneQty,
        lot_name: lot, 
      }:{
        move_id: data.id,
        done_qty: +doneQty,
      }
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    if (data.tracking === 'lot') {
      lotRef.current.focus();
    } else {
      qtyRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 "> {POPUP_MSG.ENTER_QTY} </h4>
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          {data.lotNo !== 'N/A' && (
            <div className="d-flex justify-content-between w-100 ">
              <label className="ms-3">Lot No.</label>
              <input
                type="text"
                className="ps-2 curve-border me-4"
                placeholder={data.lotNo || ''}
                ref={lotRef}
                onChange={(e) => {
                  setLot(e.target.value);
                }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.received_quantity || ''}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const ReceiptValidate = ({ apiCall, setOpenConfirmationBox, id }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(false);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(false);
    apiCall({
      picking_id: id,
    });
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 "> {POPUP_MSG.SURE}</h4>
          <p className="text-center text-special placeholder-color ">{POPUP_MSG.QTY_WILL_SAVE}</p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const shouldShowValidateButton = (data) => {
  for (const item of data) {
    return item.state === 'assigned' ? true : false;
  }
};
