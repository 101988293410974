import React, { useEffect, useState } from 'react';
import { DetailBox, Table, TitleBar } from '../../../common';
import { TITLES, ROUTES, FABRIC_PRODUCTION_DETAILS_COLUMN, BUTTON } from '../../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { FabricProductionModal } from './FabricProduction';
import { Button, PopUpBox } from '../../../shared';
import { dispatchDetailsBox } from '../../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../../hooks';
import { validateDispatch, vendorReceipt } from '../../../../service/receipt';
import { setLoaderVisibility } from '../../../../redux';
import { fabricManufacturingProductCompiler, popup } from '../../../../utils';

export const ProductionDetails = () => {
  const { state } = useLocation(location?.state);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [popUpData, setPopUpData] = useState({});
  const [reload, setReload] = useState(false);
  const [column, setColumn] = useState(FABRIC_PRODUCTION_DETAILS_COLUMN);
  const dispatch = useDispatch();

  let enableValidate = () => {
    return productData.length ? productData.every((move) => move.lotno) : false;
  };

  const handleValidate = useDebounce(() => {
    // API Call to load data
    validateDispatch({
      data: { picking_id: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.MANUFACTURING);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  const getProductsList = useDebounce(() => {
    // API Call to load data
    setReload(false);
    vendorReceipt({
      data: { receipt_ids: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setProductData(fabricManufacturingProductCompiler(response.data[0]));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, [reload]);

  useEffect(() => {
    if (productData.length > 0) {
      if (productData.every((move) => move.edit))
        setColumn(FABRIC_PRODUCTION_DETAILS_COLUMN.filter((col) => col.name !== 'Action'));
    }
  }, [productData]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING },
          { text: state?.name, route: ROUTES.FABRIC_DISPATCH_DETAIL },
        ]}
      />
      <div className="scroll-div">
        {(state?.state === 'draft' || state?.state === 'assigned') && (
          <div className="d-flex justify-content-end gap-2 mb-3">
            <Button
              disabled={!enableValidate()}
              clickHandler={handleValidate}
              className={`border-0 blue-btn ${enableValidate() ? '' : 'disabled'} d-flex align-items-center justify-content-center py-2 px-3`}>
              {BUTTON.VALIDATE}
            </Button>
          </div>
        )}
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <Table
          columns={column}
          initialData={productData}
          PAGE_SIZE={4}
          colFilter={false}
          popUp={isModalOpen}
          setPopUp={setIsModalOpen}
          setPopUpData={setPopUpData}
        />
      </div>
      {isModalOpen && (
        <PopUpBox openPopUpBox={isModalOpen}>
          <FabricProductionModal
            closePopUp={setIsModalOpen}
            modalData={popUpData}
            setReload={setReload}
          />
        </PopUpBox>
      )}
    </>
  );
};
