import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'loader slice',
  initialState: false,
  reducers: {
    setLoaderVisibility: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setLoaderVisibility } = slice.actions;
export default slice.reducer;
