export function dispatchDataCompiler(data = []) {
  let finalData = [];
  data.map((obj) => {
    let dispatchData = {
      url: obj.name.replaceAll('/', '_'),
      id: obj.id,
      shipment_no: obj.name,
      delivery_contact: obj.partner_id.name,
      shipment_date: obj.scheduled_date.split(' ')[0],
      box_count: obj.box_count,
      status: obj.state,
      origin: obj.origin || '',
      data: obj,
    };
    finalData.push(dispatchData);
  });
  return finalData;
}

export function dispatchDetailsBox(dispatch) {
  let finalData = [
    { name: 'Name', col: 1, data: dispatch?.name },
    { name: 'Partner', col: 1, data: dispatch?.partner_id.name },
    { name: 'Scheduled Date', col: 2, data: dispatch?.scheduled_date.split(' ')[0] },
    { name: 'State', col: 2, data: dispatch?.state.toUpperCase() },
  ];
  return finalData;
}

export function fabricDispatchProductCompiler(data = []) {
  let finalData = [];
  data.map((obj) => {
    let dispatchProductsData = {
      move_id: obj.id,
      lotno: obj.product_id.tracking === 'lot' ? obj.lot_ids[0]?.name || '-' : 'N/A',
      demand_quantity: obj.product_uom_qty,
      producing_quantity: obj.quantity,
      unit: obj.product_uom?.name,
      product: obj.product_id?.display_name,
      data: obj,
      edit: obj.subcontract_has_been_recorded,
    };
    finalData.push(dispatchProductsData);
  });
  return finalData;
}

export function shipmentDataCompiler(data) {
  let finalData = [];
  data.package_level_ids.map((obj) => {
    let shipmentData = {
      package_id: obj.package_id?.id,
      BLP: obj.package_id?.name,
      SKU: obj.package_id?.quant_ids[0]?.product_id?.default_code,
      Batch: obj.package_id?.quant_ids[0]?.lot_id?.name,
      SKU_Details: obj?.package_id.quant_ids?.map((sku) => ({
        SKU: sku.product_id?.display_name,
        'Batch No.': sku.lot_id?.name,
        Quantity: sku.quantity + ' Unit',
      })),
      data: obj,
    };
    finalData.push(shipmentData);
  });
  return finalData;
}
// {
//   id: 49,
//   package_id: {
//     id: 35,
//     name: 'PACK0000036',
//     quant_ids: [
//       {
//         id: 258,
//         product_id: {
//           id: 60,
//           default_code: 'ACL012X',
//           display_name: '[ACL012X] Active S/S (Black, 2XL)',
//         },
//         quantity: 40.0,
//         lot_id: {
//           id: 93,
//           name: 'ESUN/00043',
//         },
//       },
//     ],
//   },
// },