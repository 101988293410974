import React from 'react';
const D1 = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.4736 40.4819H24.4652V47.4735H17.4736V40.4819Z" fill="#4B5564" />
    <path d="M17.4736 57.0371H24.4652V64.0287H17.4736V57.0371Z" fill="#4B5564" />
    <path d="M17.4736 23.9268H24.4652V30.9184H17.4736V23.9268Z" fill="#4B5564" />
    <path
      d="M47.8918 22.3135H58.2363L44.396 8.47266V18.8177C44.396 20.7452 45.9643 22.3135 47.8918 22.3135Z"
      fill="#4B5564"
    />
    <path
      d="M43.6056 65.1791C42.74 64.2148 42.323 62.9228 42.4622 61.635L44.4984 42.7421C44.7472 40.4311 46.6775 38.6886 48.9968 38.665V37.5026C48.9968 32.5919 52.9916 28.5966 57.9023 28.5966C58.1746 28.5966 58.4429 28.6135 58.709 28.6375V23.927H47.8918C45.0746 23.927 42.7826 21.635 42.7826 18.8177V8H10.958C9.32721 8 8 9.32668 8 10.958V69.042C8 70.6733 9.32721 72 10.958 72H55.751C57.3818 72 58.709 70.6733 58.709 69.042V66.6964H47.0073C45.7117 66.6964 44.4716 66.1434 43.6056 65.1791ZM31.3204 26.6161H43.5893C44.0347 26.6161 44.396 26.9774 44.396 27.4228C44.396 27.8682 44.0347 28.2295 43.5893 28.2295H31.3204C30.875 28.2295 30.5137 27.8682 30.5137 27.4228C30.5137 26.9774 30.875 26.6161 31.3204 26.6161ZM26.0782 64.8351C26.0782 65.2805 25.7169 65.6418 25.2715 65.6418H16.6665C16.2211 65.6418 15.8598 65.2805 15.8598 64.8351V56.23C15.8598 55.7847 16.2211 55.4233 16.6665 55.4233H25.2715C25.7169 55.4233 26.0783 55.7847 26.0783 56.23L26.0782 64.8351ZM26.0782 48.2805C26.0782 48.7258 25.7169 49.0872 25.2715 49.0872H16.6665C16.2211 49.0872 15.8598 48.7258 15.8598 48.2805V39.6754C15.8598 39.23 16.2211 38.8687 16.6665 38.8687H25.2715C25.7169 38.8687 26.0783 39.23 26.0783 39.6754L26.0782 48.2805ZM26.0782 31.7253C26.0782 32.1707 25.7169 32.532 25.2715 32.532H16.6665C16.2211 32.532 15.8598 32.1707 15.8598 31.7253V23.1203C15.8598 22.6749 16.2211 22.3136 16.6665 22.3136H25.2715C25.7169 22.3136 26.0783 22.6749 26.0783 23.1203L26.0782 31.7253ZM36.8797 61.3393H31.3204C30.875 61.3393 30.5136 60.9779 30.5136 60.5326C30.5136 60.0872 30.875 59.7258 31.3204 59.7258H36.8797C37.3251 59.7258 37.6864 60.0872 37.6864 60.5326C37.6864 60.9779 37.3251 61.3393 36.8797 61.3393ZM40 44.7847H31.3204C30.875 44.7847 30.5136 44.4233 30.5136 43.9779C30.5136 43.5326 30.875 43.1712 31.3204 43.1712H40C40.4454 43.1712 40.8067 43.5326 40.8067 43.9779C40.8067 44.4233 40.4454 44.7847 40 44.7847Z"
      fill="#4B5564"
    />
    <path
      d="M71.7391 61.8077L69.7029 42.9153C69.5419 41.422 68.2964 40.2961 66.7984 40.2775V46.3098C66.7984 46.7551 66.4371 47.1165 65.9917 47.1165C65.5551 47.1165 65.2038 46.7677 65.19 46.3344C65.1902 46.3258 65.195 46.3185 65.195 46.3098V37.5025C65.195 33.4815 61.9235 30.21 57.9024 30.21C53.8814 30.21 50.6104 33.4815 50.6104 37.5025V40.274H49.0432C47.529 40.274 46.2648 41.4095 46.1025 42.9148L44.0663 61.8077C43.9765 62.6417 44.2459 63.4773 44.8063 64.1012C45.3667 64.7252 46.1687 65.0829 47.0075 65.0829H68.7979C69.6367 65.0829 70.4387 64.7252 70.9991 64.1012C71.5595 63.4773 71.8289 62.6417 71.7391 61.8077ZM53.8268 46.3098C53.8268 46.7551 53.4655 47.1165 53.0201 47.1165C52.5839 47.1165 52.2331 46.7685 52.2186 46.3358C52.219 46.3266 52.2239 46.3189 52.2239 46.3098V37.5025C52.2239 34.3712 54.7711 31.8234 57.9024 31.8234C61.0338 31.8234 63.5815 34.3712 63.5815 37.5025V40.274H53.8268V46.3098Z"
      fill="#4B5564"
    />
  </svg>
);
export default D1;
