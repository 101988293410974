import React from 'react';
const EditIcon = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.83154 14.1286H14.6666"
      stroke="#58616F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18651 3.02986C9.7036 2.41186 10.6332 2.32124 11.264 2.82782C11.2989 2.85531 12.4195 3.72586 12.4195 3.72586C13.1125 4.14479 13.3278 5.0354 12.8994 5.71506C12.8767 5.75146 6.54113 13.6763 6.54113 13.6763C6.33035 13.9393 6.01039 14.0945 5.66844 14.0982L3.24219 14.1287L2.69552 11.8149C2.61895 11.4895 2.69552 11.1478 2.9063 10.8849L9.18651 3.02986Z"
      stroke="#58616F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.01367 4.50061L11.6485 7.29203"
      stroke="#58616F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditIcon;
