// import { getReadyQty } from '../../components/pages/manufacturing/ManufacturingContent';

export const manufacturingDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let mfgData = {
      url: obj.future_lot,
      lot_no: obj.future_lot,
      SKU: obj.product_id.default_code || obj.product_id.display_name,
      total_qty: obj.product_uom_qty,
      priority: obj.manufacture_priority,
      batch: obj.batch_ids,
      id: obj.id,
      product_id: obj.product_id.id,
      display_name: obj.product_id.display_name,
      ready_qty: obj.produced_qty,
      qty_status:
        obj.produced_qty === 0
          ? 'Pending'
          : obj.produced_qty < obj.product_uom_qty
            ? 'Partial'
            : 'Completed',
    };
    finalData.push(mfgData);
  });
  return finalData;
};

export const batchDataCompiler = (obj) => {
  let finalData = {
    product_id: obj.product_id.id,
    batch: obj.batch_ids,
    lotData: obj,
    SKU: obj.product_id.default_code,
    lot_no: obj.future_lot,
    total_qty: obj.product_uom_qty,
    ready_qty: obj.produced_qty,
    move_id: obj.move_id,
  };
  return finalData;
};
