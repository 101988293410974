import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const vendorLogin = ({ data }) => {
  const url = API.VENDOR_LOGIN;
  const response = fetchData({ body: data, method: METHODS.POST, url });
  return response;
};

export const vendorLogout = async () => {
  const url = API.VENDOR_LOGOUT;
  const response = await fetchData({ body: {}, method: METHODS.POST, url });
  return response;
};
