import React from 'react';
const D5 = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.6732 18.5137V18.4975L59.7909 23.9249L71.957 19.3636L39.5786 7.22168L7.2002 19.3636L39.5786 31.5055L51.6962 26.9604L39.5786 21.966V21.9539L47.6732 18.5137Z"
      fill="#4B5564"
    />
    <path
      d="M7.2002 23.4111V59.8369L37.555 71.2219V34.7962L7.2002 23.4111ZM23.3894 59.9704L15.2948 56.9349V52.6165L23.3894 55.6519V59.9704Z"
      fill="#4B5564"
    />
    <path
      d="M59.8149 27.9643V38.1028L51.7203 41.1383V30.9998L41.6021 34.7962V71.2219L71.9568 59.8369V23.4111L59.8149 27.9643Z"
      fill="#4B5564"
    />
  </svg>
);
export default D5;
