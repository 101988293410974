import React from 'react';

const DropDownArrow2 = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1666 5.66699L8.49992 10.3337L3.83325 5.66699"
      stroke="#58616F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DropDownArrow2;
